import { ForwardedRef, forwardRef, useRef } from 'react';
import type SimpleBar from 'simplebar-react';

import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrIllustration from '@components/common/BrIllustration';

import BrDropdown from '../BrDropdown';
import VirtualOptionList from './VirtualOptionList';

import useDynamicHeight from './useDynamicHeight';

type CustomItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (option: BrOptionProps) => void;
};
interface Props {
  searchValue?: string;
  notFoundText?: string;
  dropdownMaxHeight?: number;
  isOpen?: boolean;
  options: BrOptionProps[];
  selectedOption?: BrOptionProps;
  addonBottom?: React.ReactNode;
  onSelect(option: BrOptionProps): void;
  onAnimationEnd?(): void;
  renderItem?(item: CustomItem, itemIdx: number): JSX.Element;
}

const DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT = 248;
const ADDON_BOTTOM_RESERVED_HEIGHT = 86;

const Dropdown = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    searchValue,
    selectedOption,
    options,
    notFoundText,
    isOpen,
    onSelect,
    renderItem,
    addonBottom,
    dropdownMaxHeight = DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT,
    onAnimationEnd,
  } = props;

  const listRef = useRef<React.MutableRefObject<SimpleBar> | null>(null);

  const optionListMaxHeight = addonBottom
    ? dropdownMaxHeight - ADDON_BOTTOM_RESERVED_HEIGHT
    : dropdownMaxHeight;

  const { dynamicHeightValue } = useDynamicHeight({
    listRef,
    maxHeight: optionListMaxHeight,
    deps: [searchValue, isOpen, options.length],
  });

  const handleOnAnimationEnd = () => {
    if (!isOpen) {
      // TODO: there is a lib's related type issue, for now I just put ts-ignore
      // @ts-ignore
      if (listRef.current?.current?.scrollTop) {
        // TODO: there is a lib's related type issue, for now I just put ts-ignore
        // @ts-ignore
        listRef.current.current.scrollTop = 0;
      }
    }
    onAnimationEnd?.();
  };

  return (
    <BrDropdown
      isOpen={isOpen}
      ref={ref}
      style={{ maxHeight: dropdownMaxHeight }}
      onAnimationEnd={handleOnAnimationEnd}
    >
      {/* Do not remove options list otherwise it will break dynamic height calculation because of ref */}
      <VirtualOptionList
        options={options}
        onSelect={onSelect}
        renderItem={renderItem}
        selectedOption={selectedOption}
        dropdownHeight={dynamicHeightValue}
        listRef={listRef}
      />
      {!options.length && (
        <div className="w-full h-full flex flex-col items-center justify-center p-default gap-small">
          <BrIllustration type="notFound" />
          <div className="text-body/caption/default text-support-colors/highlights">
            {notFoundText}
          </div>
        </div>
      )}
      {addonBottom && (
        <>
          <div className="absolute w-full left-0 bottom-0 h-[86px] rounded-default p-default bg-color/primary shadow-md">
            {addonBottom}
          </div>
          <div className="h-[86px]" />
        </>
      )}
    </BrDropdown>
  );
});

export default Dropdown;
