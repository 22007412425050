import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { getCountryNameByCode } from '@services/countryList';

import CountryCode from '@root/interfaces/CountryCode';
import CallingPlan from '@root/models/CallingPlans/CallingPlan';

import BrCountrySelect from '@components/common/BrCountrySelect';

import BrFilter from '@components/common/BrFilter';

import PlansListSkeleton from './components/PlansList/skeleton';
import PlansList from './components/PlansList/index';
import useCallingPlansFilter from './useCallingPlansFilter';

interface Props {
  destinationCountries: CountryCode[];
  availablePlans: CallingPlan[];
  activePlans: CallingPlan[];
  suggestedPlans: CallingPlan[];
  nonPurchasablePlans: CallingPlan[];
  selectedCountryCode?: CountryCode;
  selectedPlanId?: string;
  dataTestPrefix?: string;
  onCountrySelect?(countryCode: CountryCode): void;
  onPlantSelect?(planId: string): void;
}

const getCountryFilterFn = (countryCode?: CountryCode) => {
  return (plan: CallingPlan) => {
    if (countryCode) {
      return plan.destinationCountries.includes(countryCode);
    }
    return plan;
  };
};

const Setup: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    destinationCountries,
    availablePlans,
    suggestedPlans,
    activePlans,
    nonPurchasablePlans,
    selectedCountryCode,
    selectedPlanId,
    onCountrySelect,
    onPlantSelect,
  } = props;

  const { t } = useTranslation();

  const isLoading = !(availablePlans.length || activePlans.length);

  const { filterOptions, filterFnsPipe, handleFilterSelect } = useCallingPlansFilter();

  const filteredAvailablePlans = filterFnsPipe(availablePlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );
  const filteredSuggestedPlans = filterFnsPipe(suggestedPlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );
  const filteredNonPurchasablePlans = filterFnsPipe(nonPurchasablePlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );

  return (
    <div className="flex flex-col space-y-default">
      {isLoading ? (
        <PlansListSkeleton />
      ) : (
        <>
          <div className="flex flex-col space-y-small">
            <div className="text-body/primary/demibold">{t('Calling destination')}</div>
            <BrCountrySelect
              data={destinationCountries.map((item) => {
                return {
                  code: item,
                  value: item,
                  textLabel: t(getCountryNameByCode(item) || ''),
                };
              })}
              value={selectedCountryCode || ''}
              hasSearch
              placeholder={t('Select or type a country')}
              drawerTitleText={t('Calling destinations')}
              onChange={onCountrySelect}
            />
          </div>
          <div className="flex flex-col space-y-xsmall pb-xlarge">
            {activePlans.length ? (
              <PlansList
                title={t('Active plans')}
                data={activePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            <div className="overflow-x-auto br-container-x-gutter py-default">
              <BrFilter
                options={filterOptions}
                defaultValue="all"
                shouldFallbackToDefault
                isMultiSelect
                onItemSelect={handleFilterSelect}
              />
            </div>

            {filteredSuggestedPlans.length ? (
              <PlansList
                title={t('Suggested plans')}
                data={filteredSuggestedPlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            {filteredAvailablePlans.length ? (
              <PlansList
                title={t('Available plans')}
                data={filteredAvailablePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            {filteredNonPurchasablePlans.length ? (
              <PlansList
                title={t('Ineligible plans')}
                data={filteredNonPurchasablePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Setup);
