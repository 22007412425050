import React from 'react';
import { useParams } from 'react-router-dom';

import ServiceTemplate from '@components/templates/ServiceTemplate';

import CallingRechargeReceipt from '@components/modules/CallingRechargeReceipt';

interface UrlParams {
  id: string;
}

const CallingRechargeReceiptPage = () => {
  const { id } = useParams<UrlParams>();

  return (
    <ServiceTemplate>
      <CallingRechargeReceipt txnId={id} />
    </ServiceTemplate>
  );
};

export default CallingRechargeReceiptPage;
