import React, { useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BASE_PATHS from '@root/routes/paths';

import useDidUpdate from '@root/hooks/useDidUpdate';
import useDidMount from '@root/hooks/useDidMount';
import useBrModal from '@root/hooks/useBrModal';

import { externalUrlRegexp } from '@root/constants/regexpPatterns';

import CallingRechargeStore from '@root/stores/CallingRechargeStore';
// TODO: move to root/stores
import CallingPlansStore from '@components/modules/CallingPlans/CallingPlansStore';

import {
  Grid,
  Button,
  RouteLeavingGuard,
  PageTitle,
  AdaptiveHelpButton,
  AdaptiveCancelButton,
  Spinner,
} from '@components/common';
import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';
import AutoRechargeToggle from '@components/common/CallingAutoRechargeToggle';
import CallingRechargeAmountList from '@components/common/CallingRechargeAmountList';
import BrModalConfirmationContent from '@components/common/BrModalConfirmationContent';

import Store from './Store';

const AutoRecharge = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const callingRechargeStore = useLocalStore(() => new CallingRechargeStore());
  const callingPlansStore = useLocalStore(() => new CallingPlansStore());
  const store = useLocalStore(() => new Store());

  const savingsPassWarningModalContent = (
    onConfirm: () => void,
    onDecline: () => void,
  ) => (
    <BrModalConfirmationContent
      title={t('Turning off Auto-recharge')}
      dismissText={t('Cancel')}
      confirmText={t('Turn Off')}
      onConfirm={onConfirm}
      onDecline={onDecline}
    >
      {t(
        'Auto-recharge must be turned on to keep your Savings Pass active. Are you sure you want to turn off the auto-recharge?',
      )}
    </BrModalConfirmationContent>
  );
  const {
    open: showSavingsPassWarningModal,
    close: closeSavingsPassWarningModal,
  } = useBrModal({
    content: savingsPassWarningModalContent,
    hasCloseButton: false,
  });

  const DATA_TEST_ID_PREFIX = `auto-recharge`;

  useDidMount(async () => {
    await callingRechargeStore.getCallingInfo();
    await callingPlansStore.fetchPlans();
  });

  const isAutoRechargeEnabled = useMemo(() => {
    return (
      callingRechargeStore.isAutoRechargeRecommended ||
      callingRechargeStore.isAutoRechargeSet
    );
  }, [
    callingRechargeStore.isAutoRechargeRecommended,
    callingRechargeStore.isAutoRechargeSet,
  ]);

  useDidUpdate(() => {
    store.setSelectedAmount(callingRechargeStore.paymentInfo?.arAmount);
  }, [callingRechargeStore.paymentInfo?.arAmount]);

  useDidUpdate(() => {
    store.setAutoRechargeEnabled(isAutoRechargeEnabled);
  }, [isAutoRechargeEnabled]);

  const handleAutoRechargeValueChange = (value: boolean) => {
    if (value && callingRechargeStore.paymentInfo) {
      callingRechargeStore.setupAutoRechargeAmount(
        callingRechargeStore.paymentInfo.defaultAutoRechargeAmount,
      );
    } else {
      callingRechargeStore.disableAutoRecharge();
    }

    store.setAutoRechargeEnabled(value);
    if (!value) {
      store.setSelectedAmount(undefined);
      store.setHasUnsavedChanges(false);
    }
  };

  const handleAutoRechargeUpdate = (value: number) => {
    callingRechargeStore.setupAutoRechargeAmount(value, () => {
      history.push(BASE_PATHS.ACCOUNT);
    });
  };

  const handleAmountSelect = (value: string) => {
    store.setSelectedAmount(value);
    store.setAutoRechargeEnabled();
    store.setHasUnsavedChanges();
  };

  const handleAutoRechargeToggleClick = async (value: boolean) => {
    if (callingPlansStore.isSavingsPassActive && !value) {
      const isConfirmed = await showSavingsPassWarningModal();
      closeSavingsPassWarningModal();
      if (!isConfirmed) {
        return;
      }
    }

    handleAutoRechargeValueChange(value);
  };

  const handleArUpdateConfirmation = () => {
    if (store.selectedAmount) {
      handleAutoRechargeUpdate(store.selectedAmount);
      store.setHasUnsavedChanges(false);
    }
  };

  const navigateBack = () => {
    store.setHasUnsavedChanges(false);
    history.goBack();
  };

  const arActiveDescription = t(
    'Choose the amount that will be added to your account whenever your balance falls below {{amount}}.',
    {
      amount: `${callingRechargeStore.paymentInfo?.currencySymbol}${callingRechargeStore.paymentInfo?.thresholdAmount} ${callingRechargeStore.paymentInfo?.currency}`,
    },
  );

  const arDisabledDescription = t(
    'Turn auto-recharge on, then choose an auto-recharge amount.',
  );

  const handleCancelButtonClick = () => {
    history.push(BASE_PATHS.CALLING);
  };

  return (
    <>
      <RouteLeavingGuard
        title={t('Do you want to save changes?')}
        when={store.hasUnsavedChanges}
        shouldBlockNavigation={(nextLocation) => {
          return store.hasUnsavedChanges || externalUrlRegexp.test(nextLocation.pathname); // pathname includes https?:// or www.
        }}
        buttonConfig={[
          {
            label: t('Yes'),
            type: 'OK',
            onClick: handleArUpdateConfirmation,
          },
          {
            label: t('No'),
            type: 'Cancel',
            onClick: navigateBack,
          },
        ]}
      />
      <Spinner
        isSpinning={callingRechargeStore.isAnythingLoading || callingPlansStore.isLoading}
      >
        <PageTitle
          title={t('manage auto-recharge')}
          buttonsNode={
            <>
              <AdaptiveHelpButton />
              <AdaptiveCancelButton onClick={handleCancelButtonClick} />
            </>
          }
          hasBossLogoLink={isDesktop}
          hasFixedPosition
        />

        <div className="mt-10 mb-24">
          <Grid.Container className="flex flex-col gap-8">
            <Grid.Row className="text-center">
              <Grid.Col span={{ sm: 12, lg: 4 }} offset={{ lg: 4 }} className="mb-4">
                <p className="text-2xl font-bold">
                  {t('Choose an auto-recharge amount and never run out of talk time')}
                </p>
              </Grid.Col>

              <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
                <CallingRechargeAmountList
                  value={store.selectedAmount}
                  options={callingRechargeStore.paymentInfo?.creditCardTiers || []}
                  onChange={handleAmountSelect}
                  className="mx-auto"
                  dataTestId={`${DATA_TEST_ID_PREFIX}-select-recharge`}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="text-center">
              <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }} className="mb-10">
                <AutoRechargeToggle
                  value={store.isAutoRechargeEnabled}
                  onChange={handleAutoRechargeToggleClick}
                  dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
                />
              </Grid.Col>

              <Grid.Col span={{ sm: 12, lg: 6 }} offset={{ lg: 3 }}>
                <p className="text-gray-400">
                  {store.isAutoRechargeEnabled
                    ? arActiveDescription
                    : arDisabledDescription}
                </p>
              </Grid.Col>

              <BottomControlsWrapper>
                <Button
                  shape="pill"
                  color="blue"
                  size="lg"
                  onClick={handleArUpdateConfirmation}
                  disabled={!store.hasUnsavedChanges}
                  dataTestId={`${DATA_TEST_ID_PREFIX}-save`}
                >
                  {t('Save changes')}
                </Button>
              </BottomControlsWrapper>
            </Grid.Row>
          </Grid.Container>
        </div>
      </Spinner>
    </>
  );
};

export default observer(AutoRecharge);
