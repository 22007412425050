import { CallingPlanOrderStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import { ReactComponent as SuccessIcon } from './images/success.svg';
import { ReactComponent as FailIcon } from './images/fail.svg';
import { ReactComponent as QueuedIcon } from './images/queued.svg';
import { ReactComponent as ReceiptIcon } from './images/receipt.svg';

const statusIconMap: { [statusKey in CallingPlanOrderStatus]: React.FC } = {
  success: SuccessIcon,
  failed: FailIcon,
  queued: QueuedIcon,
  receipt: ReceiptIcon,
};

interface Props {
  status: CallingPlanOrderStatus;
  className?: string;
}

const BrOrderStatusIllustration = (props: Props) => {
  const { status, className } = props;

  const Illustration = statusIconMap[status];

  return (
    <div className={className}>
      <Illustration />
    </div>
  );
};

export default BrOrderStatusIllustration;
