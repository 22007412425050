import { makeAutoObservable } from 'mobx';

import {
  addContactToPlan,
  deleteContactFromPlan,
  fetchCallingPlansContacts,
} from '@services/api/v1/dtcCallingPlans';

import CallingPlanContact from '@root/models/CallingPlans/CallingPlanContact';

class CallingPlanContactsStore {
  planId: string;

  contacts: CallingPlanContact[] = [];

  isLoading = false;

  constructor(planId: string) {
    makeAutoObservable(this);

    this.planId = planId;
  }

  get phoneNumbers() {
    return this.contacts.map((item) => {
      return item.phoneNumber;
    });
  }

  setContacts(contacts: CallingPlanContact[]) {
    this.contacts = contacts;
  }

  getContacts = async () => {
    try {
      this.setLoading(true);
      const res = await fetchCallingPlansContacts(this.planId);
      this.contacts = res;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      this.setLoading(false);
    }
  };

  addContact = async (phoneNumber: string) => {
    try {
      this.setLoading(true);
      await addContactToPlan({ planId: this.planId, phoneNumber });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      this.setLoading(false);
    }
  };

  deleteContact = async (phoneNumber: string) => {
    try {
      this.setLoading(true);
      await deleteContactFromPlan({ planId: this.planId, phoneNumber });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      this.setLoading(false);
    }
  };

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

export default CallingPlanContactsStore;
