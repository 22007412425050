import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

import { formatMoneyToText } from '@helpers/money';

import { CallingPlanType } from '@root/interfaces/contract/dtcImtuCallingPlans/Offers';
import CallingPlanProduct, {
  CallingPlanProductData,
} from '@root/models/CallingPlans/CallingPlanProduct';

import { CALLING_PLANS_TYPES } from '@root/constants/CallingPlans/callingPlansTypes';

export interface CallingPlanDestination {
  countryCode: CountryCode;
  description: string;
  descriptionIconUrl?: string;
  imageUrls: string[];
}

export interface CallingPlanFootnote {
  iconUrl?: string;
  text: string;
}

export interface CallingPlanData {
  productId: string;
  planId: string;
  classId: number;
  titleFull: string;
  titleShort: string;
  descriptionShort: string;
  descriptionFull: string;
  type: CallingPlanType;
  destinations: CallingPlanDestination[];
  destinationFootnotes: CallingPlanFootnote[];
  comboPromoId?: string;
  comboProducts?: CallingPlanProductData[];
  comboPromoTagline?: string;
  carrierLogos: string[];
  minutesLeft?: number;
  basePrice: number;
  currency: string;
  expirationDate?: number;
  txnId?: string;
  maxFavoriteNumbers?: number;
  isSubscriptionType: boolean;
  isSubscriptionActive: boolean;
  isNoNPurchasable?: boolean;
  isExtendable: boolean;
  isActive: boolean;
}

class CallingPlan {
  productId: string;

  planId: string;

  classId: number;

  titleFull: string;

  titleShort: string;

  type: CallingPlanType;

  descriptionShort: string;

  descriptionFull: string;

  carrierLogos: string[];

  minutesLeft?: number; // minutes?

  maxFavoriteNumbers?: number;

  basePrice: number;

  currency: string;

  destinations: CallingPlanDestination[];

  destinationFootnotes: CallingPlanFootnote[];

  isNoNPurchasable?: boolean;

  isSubscriptionType: boolean;

  isSubscriptionActive: boolean;

  expirationDate?: Date;

  comboPromoId?: string;

  comboPromoTagline?: string;

  comboProducts?: CallingPlanProduct[];

  txnId?: string;

  isFree: boolean;

  isActive: boolean; // 'A' for active

  isExtendable: boolean;

  constructor(data: CallingPlanData) {
    makeAutoObservable(this);

    this.productId = data.productId;
    this.planId = data.planId;
    this.classId = data.classId;

    this.titleFull = data.titleFull;
    this.titleShort = data.titleShort;
    this.descriptionShort = data.descriptionShort;
    this.descriptionFull = data.descriptionFull;

    this.type = data.type;

    this.carrierLogos = data.carrierLogos;

    this.minutesLeft = data.minutesLeft;

    this.maxFavoriteNumbers = data.maxFavoriteNumbers;

    this.basePrice = data.basePrice;
    this.currency = data.currency;

    this.expirationDate = data.expirationDate ? new Date(data.expirationDate) : undefined;

    this.comboPromoId = data.comboPromoId;
    this.comboProducts = data.comboProducts?.map((item) => {
      return new CallingPlanProduct(item);
    });
    this.comboPromoTagline = data.comboPromoTagline;

    this.destinations = data.destinations;
    this.destinationFootnotes = data.destinationFootnotes;

    this.txnId = data.txnId;

    this.isActive = data.isActive;
    this.isNoNPurchasable = data.isNoNPurchasable;
    this.isSubscriptionType = data.isSubscriptionType;
    this.isSubscriptionActive = data.isSubscriptionActive;
    this.isExtendable = data.isExtendable;
    this.isFree = !this.basePrice;
  }

  get destinationCountries() {
    return this.destinations.map((destination) => {
      return destination.countryCode;
    });
  }

  get basePriceText() {
    return formatMoneyToText(this.basePrice, this.currency);
  }

  get defaultTitle() {
    return this.titleFull; // subject to change
  }

  get isCombo() {
    return Boolean(this.comboPromoId);
  }

  get isManageable() {
    return this.isActive && !(this.isSubscriptionType && !this.isSubscriptionActive);
  }

  get isSavingsPass() {
    return this.type === CALLING_PLANS_TYPES.SAVING_PASS;
  }

  get canSubscribe() {
    return this.isSubscriptionType && (!this.isActive || this.isExtendable);
  }
}

export default CallingPlan;
