import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import useHandle3dsTxnId from '@root/hooks/useHandle3dsTxnId';

import CallingPlanOrderStatus from '@components/modules/CallingPlanOrderStatus';

import { CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

const CallingPlansOrderStatusPage = () => {
  const { txnId } = useHandle3dsTxnId({
    storeKey: CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY,
  });

  return (
    <BrTemplateWithSidebar>
      {txnId && <CallingPlanOrderStatus txnId={txnId} />}
    </BrTemplateWithSidebar>
  );
};

export default CallingPlansOrderStatusPage;
