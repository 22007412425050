import { ForwardedRef, TransitionEvent, forwardRef } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { BrDropdownProps } from '@root/interfaces/components/BrDropdown';

import './styles.scss';

const BrDropdown = forwardRef(
  (props: BrDropdownProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      isOpen,
      children,
      className,
      onAnimationEnd,
      dropdownAddonBottom,
      style,
    } = props;

    const handleOnTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        onAnimationEnd?.();
      }
    };

    return (
      <div className="br-dropdown relative" style={style}>
        <div
          onTransitionEnd={handleOnTransitionEnd}
          ref={ref}
          className={normalizeStringCompound([
            'absolute top-xsmall w-full origin-top scale-y-0 transform rounded-default bg-white opacity-0 shadow-md duration-300 ease-in-out [transition-property:transform,opacity] overflow-hidden z-10',
            isOpen
              ? '[.br-select-opened_&]:scale-y-100 [.br-select-opened_&]:opacity-100'
              : 'scale-y-0 opacity-0',
            className,
          ])}
        >
          {children}
        </div>
        {dropdownAddonBottom}
      </div>
    );
  },
);

export default BrDropdown;
