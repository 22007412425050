import { makeAutoObservable } from 'mobx';

import { API_ERROR_MSG } from '@root/constants/errorMessages';
import { CALLING_PLANS_STATUSES } from '@root/constants/CallingPlans/callingPlansStatuses';

import {
  trackBackendFailure,
  trackSentCallingPlanTransaction,
} from '@services/analytics';
import {
  BackendFailureCallName,
  BackendFailureCategory,
} from '@services/analytics/constants';

import { getCallingApiErrorMessage } from '@services/axios/helpers';
import { showToast } from '@services/toasts';

import {
  CallingPlanPurchaseInfo,
  CallingPlanPurchaseParams,
  cancelCallingPlanSubscription,
  CancelSubscriptionParams,
  ComboPlanPurchaseParams,
  SavingsPassCallingPlanPurchaseParams,
  purchaseCallingPlan,
  purchaseComboPlan,
  purchaseSavingsPassCallingPlan,
} from '@services/api/v1/dtcCallingPlans';
import CountryCode from '@root/interfaces/CountryCode';

type PurchaseParams =
  | CallingPlanPurchaseParams
  | ComboPlanPurchaseParams
  | SavingsPassCallingPlanPurchaseParams;

class CallingPlansPurchaseStore {
  isLoading = false;

  status?: string;

  errorTextStatus?: string;

  purchaseRes?: CallingPlanPurchaseInfo;

  constructor() {
    makeAutoObservable(this);
  }

  doPurchase = async (params: PurchaseParams, userCountryCode: CountryCode) => {
    this.setIsLoading(true);
    try {
      let purchaseRes;
      if ('comboPlanPromoId' in params) {
        purchaseRes = await purchaseComboPlan(params);
      } else if ('arParams' in params) {
        purchaseRes = await purchaseSavingsPassCallingPlan(params);
      } else {
        purchaseRes = await purchaseCallingPlan(params);
      }

      this.purchaseRes = purchaseRes;

      if (this.purchaseRes.status === CALLING_PLANS_STATUSES.FAILED) {
        console.log('trackInfoError', params, purchaseRes);
        throw Error(purchaseRes.errorReason);
      }
      if (purchaseRes) {
        trackSentCallingPlanTransaction({
          userCountryCode,
          price: purchaseRes.price,
          currency: purchaseRes.currency,
          transactionId: purchaseRes.txnId,
          planId: params.planId,
          shouldSubscribe: params.shouldSubscribe,
          planTitle: purchaseRes.planTitle,
          planDescription: purchaseRes.planDescription,
        });
      }
    } catch (err) {
      trackBackendFailure({
        category: BackendFailureCategory.CallingPlans,
        callName: BackendFailureCallName.TransactionSetup,
        message: String(err),
      });

      this.status = CALLING_PLANS_STATUSES.FAILED;
      this.errorTextStatus =
        getCallingApiErrorMessage(String(err)) ||
        API_ERROR_MSG.APP_CALLING_TRANSACTION_FAILED;
      showToast.error(this.errorTextStatus);
    } finally {
      this.setIsLoading(false);
    }
  };

  cancelCallingPlanSubscription = async (params: CancelSubscriptionParams) => {
    this.setIsLoading(true);
    try {
      await cancelCallingPlanSubscription(params);
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };
}

export default CallingPlansPurchaseStore;
