import React, { FC } from 'react';
import Lottie from 'lottie-react-web';
import { t } from 'i18next';

import { normalizeStringCompound } from '@utils/string';

import { BrSpinnerProps } from '@root/interfaces/components/BrSpinner';

import { ReactComponent as BrSpinnerLogo } from '@src/static/assets/images/br-spinner-logo.svg';
import animation from './liquid_b.json';

const BrSpinner: FC<React.PropsWithChildren<BrSpinnerProps>> = (props) => {
  const { className } = props;

  const containerClassNames = normalizeStringCompound([
    'inset-0 flex flex-col items-center justify-center fixed z-1000 bg-color/black/70 backdrop-blur-sm backdrop-filter',
    className,
  ]);

  return (
    <div className={containerClassNames}>
      <div className="relative h-[96px] w-[96px] mb-xxlarge">
        <Lottie options={{ animationData: animation }} ariaRole="img" height={96} />
        <div className="bg-red-400 absolute inset-center z-30 w-[64px] h-[64px] rounded-full">
          <BrSpinnerLogo className="w-full h-full inline-block" />
        </div>
      </div>
      <div className="relative z-30 text-center">
        <div className="text-headers/header-3 text-on-colors/on-secondary mb-xxxlarge">
          <div>{t("We're processing")}</div>
          {t('your order')}
        </div>
        <div className="text-body/primary/default text-on-colors/on-secondary">
          {t('Please wait...')}
        </div>
      </div>
    </div>
  );
};

export default BrSpinner;
