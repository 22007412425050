import { useTranslation } from 'react-i18next';

import BrButton from '@components/common/BrButton';

import { PaymentCardFundingType } from '@root/constants/moneyTransfer/paymentCards';

interface Props {
  onConfirm(): void;
  onDecline(): void;
  maskedNumber: string;
  fundingType: PaymentCardFundingType;
}

const BrPaymentCardRemoveConfirmUi = (props: Props) => {
  const { onConfirm, onDecline, maskedNumber, fundingType } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="text-headers/header-3 mb-middle">{t('Delete payment card')}</div>
      <div className="text-body/primary/default mb-default">
        {t('Are you sure you want to delete the {{fundingType}} card {{maskedNumber}}', {
          fundingType,
          maskedNumber,
        })}
      </div>
      <div className="flex space-x-default">
        <BrButton
          className="flex-grow basis-1/2"
          cmpType="gray"
          onClick={onDecline}
          text={t('Cancel')}
        />
        <BrButton
          className="flex-grow basis-1/2"
          onClick={onConfirm}
          text={t('Proceed')}
        />
      </div>
    </>
  );
};

export default BrPaymentCardRemoveConfirmUi;
