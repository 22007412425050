/* eslint-disable @typescript-eslint/indent */
import React, { RefObject, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { t } from 'i18next';

import { PaymentCardSelect } from '@components/common';
import { SelectRef } from '@components/common/Select';

import { showBrToast } from '@services/toasts';

import delay from '@utils/delay';
import { capitalizeFirstLetterInEachWord } from '@utils/string';

import usePaymentCards from '@root/hooks/usePaymentCards';
import useBrModal from '@root/hooks/useBrModal';

import PaymentCardClass from '@root/models/PaymentCardClass';

import CountryCode from '@root/interfaces/CountryCode';
import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';
import {
  PaymentCardFundingType,
  PaymentCardFundingTypeMap,
  PaymentCardTypeShort,
} from '@root/constants/moneyTransfer/paymentCards';

import {
  BrPaymentCardAllDetailsFormProps,
  BrPaymentCardReducedDetailsDialogProps,
} from '@root/interfaces/components/BrPaymentCardForm';

import { PaymentCardBillingAddressFormValues } from '../PaymentCardBillingAddressForm';
import { PaymentCardReducedDetailsFormValues } from '../PaymentCardReducedDetailsForm';
import { PaymentCardDetailsFormValues } from '../PaymentCardDetailsForm';
import { checkIsSupportedCard } from '../PaymentCardSelect/helpers';
import BrPaymentCardRemoveConfirmUi from './BrPaymentCardRemoveConfirmUi';

import usePreselectPaymentCardLogic from './usePreselectPaymentCardLogic';

interface UseBrPaymentCardSelectParams {
  clickOutsideIgnoreElemRefs?: RefObject<HTMLDivElement>[];
  disabledPaymentMethods?: PaymentCardTypeShort[];
  disabledPaymentCardsIssuers?: PaymentCardIssuerKeys[];
  isUsingCardsWithoutBillingAllowed?: boolean;
  handleId?: string;
  userCountryOfOrigin: CountryCode;
  onCardAdded?(card: PaymentCardClass, cvv?: string): void;
  onCardEdited?(card: PaymentCardClass): void;
}

const SHOW_TOAST_DELAY = 600;

const useBrPaymentCardSelect = (params: UseBrPaymentCardSelectParams) => {
  const {
    disabledPaymentMethods,
    disabledPaymentCardsIssuers,
    isUsingCardsWithoutBillingAllowed = false,
    handleId,
    userCountryOfOrigin,
    onCardAdded,
    onCardEdited,
  } = params;

  const [isPaymentCardFormOpen, setIsPaymentCardFormOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [hasCancelBtn, setHasCancelBtn] = useState(false);

  const selectRef = useRef<SelectRef>(null);

  const {
    isLoading,
    hasPaymentCards,
    paymentCards,
    editableCard,
    addCard,
    updateCard,
    removeCard,
    setEditableCardId,
    editableCardId,
  } = usePaymentCards();

  const {
    selectedCardId,
    setSelectedCardId,
    paymentCardsWithSupportedInfo,
  } = usePreselectPaymentCardLogic({
    paymentCards,
    handleId,
  });

  const showPaymentCardForm = () => {
    setIsPaymentCardFormOpen(true);
  };

  const hidePaymentCardForm = () => {
    setIsPaymentCardFormOpen(false);
  };

  const handleFormClose = () => {
    hidePaymentCardForm();
    setEditableCardId(undefined);
    setIsEdit(false);
    setHasCancelBtn(false);
  };

  const handleOnCardAdd = () => {
    setHasCancelBtn(Boolean(paymentCards?.length));
    setIsEdit(false);
    showPaymentCardForm();
  };

  const handleEditCardClick = (id: string) => {
    setHasCancelBtn(true);
    setIsEdit(true);
    setEditableCardId(id);
    showPaymentCardForm();
  };

  const handleEditCard = (id: string) => {
    setHasCancelBtn(true);
    setEditableCardId(id);
    showPaymentCardForm();
  };

  const handleChangeCardId = (id: string) => {
    // here we handle a case when a card needs info for using in MT
    const card = paymentCardsWithSupportedInfo?.find((c) => c.data.id === id);

    if (card) {
      const isValidCard =
        isUsingCardsWithoutBillingAllowed ||
        (card.data.hasCardHolderName && card.data.hasBillingAddress);

      if (isValidCard && card.isSupported) {
        setSelectedCardId(id);
      } else {
        setEditableCardId(id);
        showPaymentCardForm();
      }
      return undefined;
    }
    return undefined;
  };

  const selectedPaymentCard = useMemo(() => {
    return paymentCards?.find((card) => card.id === selectedCardId) || paymentCards?.[0];
  }, [selectedCardId]);

  const confirmationDialogContent = (onConfirm: () => void, onDecline: () => void) =>
    selectedPaymentCard && (
      <BrPaymentCardRemoveConfirmUi
        onConfirm={onConfirm}
        onDecline={onDecline}
        maskedNumber={editableCard?.maskedNumber as string}
        fundingType={
          capitalizeFirstLetterInEachWord(
            PaymentCardFundingTypeMap[selectedPaymentCard.type],
          ) as PaymentCardFundingType
        }
      />
    );

  const { open: openConfirmationModal, close: closeConfirmationModal } = useBrModal({
    content: confirmationDialogContent,
    hasCloseButton: false,
  });

  const value =
    (selectedPaymentCard?.hasCardHolderName && selectedPaymentCard?.hasBillingAddress) ||
    isUsingCardsWithoutBillingAllowed
      ? selectedCardId || paymentCards?.[0]?.id
      : undefined;

  const handleRemoveCard = async () => {
    const isConfirmed = await openConfirmationModal();
    if (isConfirmed && editableCardId) {
      closeConfirmationModal();
      await removeCard(editableCardId);
      hidePaymentCardForm();
      if (selectedCardId === editableCardId) {
        setSelectedCardId(undefined);
      }
      setEditableCardId(undefined);
      setIsEdit(false);
      setHasCancelBtn(false);
      await delay(SHOW_TOAST_DELAY);
      showBrToast.small(`${t('Card was successfully deleted')} ✅`);
    } else {
      closeConfirmationModal();
    }
  };

  const handleCardSubmit = async (
    values:
      | (PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues)
      | PaymentCardReducedDetailsFormValues,
  ) => {
    showPaymentCardForm();
    const card = await addCard(values);
    const isCardSupported =
      card &&
      checkIsSupportedCard({
        card,
        disabledPaymentCardsIssuers,
        disabledPaymentMethods,
      });

    if (isCardSupported) {
      setSelectedCardId(card?.id);
    }
    hidePaymentCardForm();
    setIsEdit(false);
    setHasCancelBtn(false);
    if (card) {
      onCardAdded?.(card, 'cvv' in values ? values.cvv : undefined);
    }
    if (card) {
      await delay(SHOW_TOAST_DELAY);
      showBrToast.small(`${t('New card {{maskedNumber}} was added')} ✅`, {
        maskedNumber: card?.maskedNumber,
      });
    }
    return undefined;
  };

  type Type =
    | (PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues)
    | PaymentCardReducedDetailsFormValues;

  const handleCardUpdate = async (values: Type) => {
    if (editableCardId) {
      const card = await updateCard(values, editableCardId);
      const cardWithSupportedInfo = paymentCardsWithSupportedInfo?.find(
        (c) => c.data.id === card?.id,
      );
      if (card) {
        hidePaymentCardForm();
        if (cardWithSupportedInfo?.isSupported) {
          setSelectedCardId(card.handleId);
        }
        hidePaymentCardForm();
        setEditableCardId(undefined);
        onCardEdited?.(card);
        await delay(SHOW_TOAST_DELAY);
        showBrToast.small(`${t('Card {{maskedNumber}} was updated')} ✅`, {
          maskedNumber: card?.maskedNumber,
        });
      }
    }
  };

  const expDateString = editableCard?.expDate
    ? format(editableCard?.expDate, CARD_EXP_DATE_FORMAT)
    : '';

  const hasMissedDetails = Boolean(
    editableCardId && (!editableCard?.hasCardHolderName || !editableCard?.hasBillingInfo),
  );

  // hook takes care of providing all necessary <PaymentCardSelect /> props
  const selectProps: React.ComponentProps<typeof PaymentCardSelect> = {
    isLoading,
    isUsingCardsWithoutBillingAllowed,
    value,
    ref: selectRef,
    data: paymentCardsWithSupportedInfo,
    onChange: handleChangeCardId,
    onEdit: handleEditCard,
    onCardAdd: handleOnCardAdd,
    onCardEdit: handleEditCardClick,
  };

  const commonFormProps = {
    isOpen: isPaymentCardFormOpen || (!paymentCards?.length && !isLoading),
    onClose: handleFormClose,
    onSubmit: editableCardId ? handleCardUpdate : handleCardSubmit,
    onRemove: handleRemoveCard,
    handleId: editableCardId,
    isLoading,
    isEdit,
  };

  // hook takes care of providing all necessary BrPaymentCardReducedDetailsForm props
  const paymentCardReducedDetailsDialogProps: BrPaymentCardReducedDetailsDialogProps = {
    ...commonFormProps,
    values: {
      cardNumber: editableCard?.maskedNumber ?? '',
      expDateString,
      zip: editableCard?.zip ?? '',
      cvv: editableCard?.cvv ?? '',
    },
    countryCode: userCountryOfOrigin,
    hasCancelBtn,
  };

  // hook take care of providing all necessary BrPaymentCardAllDetailsForm props
  const paymentCardAllDetailsFormProps: BrPaymentCardAllDetailsFormProps<
    PaymentCardBillingAddressFormValues & PaymentCardDetailsFormValues
  > = {
    ...commonFormProps,
    values: {
      expDateString,
      cardNumber: editableCard?.maskedNumber ?? '',
      holderName: editableCard?.accountHolder ?? '',
      zip: editableCard?.zip ?? '',
      street1: editableCard?.address1 ?? '',
      street2: editableCard?.address2 ?? '',
      city: editableCard?.city ?? '',
      state: editableCard?.state ?? '',
    },
    hasMissedDetails,
    countryCode: userCountryOfOrigin,
  };

  return {
    selectProps,
    paymentCardAllDetailsFormProps,
    paymentCardReducedDetailsDialogProps,
    paymentCards,
    isLoading,
    hasPaymentCards,
    selectedPaymentCard,
    showPaymentCardForm,
    hidePaymentCardForm,
  };
};

export default useBrPaymentCardSelect;
