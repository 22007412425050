import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useWizard from '@root/hooks/useWizard';
import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';
import useStore from '@root/hooks/useStore';

import { AppConfigStore, UserAccountStore } from '@root/stores';

import {
  AdaptiveCancelButton,
  AdaptiveHelpButton,
  Grid,
  Logo,
  Modal,
  PageTitle,
  Spinner,
} from '@components/common';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import ROUTE_PATHS from '@root/routes/paths';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import { parseImtuTransactionQueryParams } from '@helpers/urlQuery';

import Stepper from '@components/common/Stepper';

import Country from './containers/Country';
import Phone from './containers/Phone';
import Carrier from './containers/Carriers';
import Plan from './containers/Plans';
import Summary from './containers/Summary';

import { ImtuStep } from './constants';

import Store from './Store';
import SendImtuTransactionStore from './SendImtuTransactionStore';
import ImtuConfigStore from './ImtuConfigStore';
import ImtuPrefillStore from './ImtuPrefillStore';
import ImtuPromoInfoStore from './ImtuPromoInfoStore';

import ImtuTxnInfoSection from './components/ImtuTxnInfoSection';
import ImtuPromosSection from './components/ImtuPromosSection';
import SectionHeader from './components/Layout/SectionHeader';
import SectionContainer from './components/Layout/SectionContainer';
import ImtuTermsBlock from './components/ImtuTermsBlock';

import { getFilteredPromos } from './helpers';

interface Props {
  basePath: string;
}

export interface StepModule {
  title: string;
  component: React.ReactNode;
}

const DATA_TEST_ID_PREFIX = 'mtu';

const InternationalMobileTopUp: FC<PropsWithChildren<Props>> = (props) => {
  const { basePath } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const store = useLocalStore(() => new Store());
  const imtuPrefillStore = useLocalStore(() => new ImtuPrefillStore());
  const sendImtuStore = useLocalStore(() => new SendImtuTransactionStore());
  const imtuConfigStore = useLocalStore(() => new ImtuConfigStore());
  const imtuPromoInfoStore = useLocalStore(() => new ImtuPromoInfoStore());

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const [isInitialDataLoading, setInitialDataLoading] = useState<boolean>(false);

  const {
    currentStepIndex,
    redirectToStep,
    stepPaths,
    availableSteps,
    redirectToNextStep,
  } = useWizard({
    basePath,
    steps: ImtuStep,
  });

  useDidMount(async () => {
    // TODO: check if order matters.
    // Will imtu config be available when Calling config says that Imtu feature is not available
    setInitialDataLoading(true);
    const prefillData = parseImtuTransactionQueryParams();
    await imtuConfigStore.fetchConfig();

    if (prefillData) {
      await imtuPrefillStore.setDataFromQueryParams(prefillData);
      store.setCountryCode(imtuPrefillStore.countryCode);
      store.setRecipientPhone(imtuPrefillStore.recipientPhone);
      store.setCarrier(imtuPrefillStore.carrier);
      store.setProduct(imtuPrefillStore.product);

      if (store.product) {
        redirectToStep(ImtuStep.Summary);
      } else if (store.carrier) {
        redirectToStep(ImtuStep.Plan);
      } else if (store.recipientPhone) {
        redirectToStep(ImtuStep.Carrier);
      } else if (store.countryCode) {
        redirectToStep(ImtuStep.Phone);
      }
    }

    setInitialDataLoading(false);
  });

  useDidUpdate(() => {
    if (!appConfigStore.availableMainFeaturesCfg.isImtuAvailable) {
      store.showImtuAvailabilityModal();
    }
  }, [appConfigStore.availableMainFeaturesCfg.isImtuAvailable]);

  const stepperProps = {
    current: currentStepIndex,
    basePath,
    paths: stepPaths.slice(0, -1), // remove the last Recipe step from here
    dataTestId: `${DATA_TEST_ID_PREFIX}-stepper`,
  };

  const isUiDataReady = [
    isInitialDataLoading,
    imtuConfigStore.isLoading,
    appConfigStore.isAnyDataLoading,
    sendImtuStore.isLoading,
    imtuPrefillStore.isLoading,
  ].every((isLoading) => {
    return !isLoading;
  });

  const handlePromoApply = async (promoCode: string) => {
    if (store.product?.code && store.recipientPhone) {
      await imtuPromoInfoStore.getPromoInfo({
        promoId: promoCode,
        offerId: store.product.code,
        recipientPhone: store.recipientPhone,
      });
    }
  };

  const handlePromoChange = () => {
    imtuPromoInfoStore.reset();
  };

  useEffect(() => {
    imtuPromoInfoStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.product?.code, store.recipientPhone]);

  const promosToDisplay = useMemo(() => {
    if (store.countryCode) {
      return getFilteredPromos({
        promos: imtuConfigStore.promos,
        countryCode: store.countryCode,
        carrierCode: store.carrier?.code,
      });
    }
    return imtuConfigStore.promos;
  }, [imtuConfigStore.promos, store.countryCode, store.carrier?.code]);

  const handleCancelClick = () => {
    history.push(ROUTE_PATHS.ACCOUNT);
  };

  const handleImtuAvailabilityModalClose = () => {
    store.hideImtuAvailabilityModal();
  };

  /**
   * Used to remove all the data filled after the destination step on step edit
   * @param stepToEdit
   */
  const resetStoreToStep = (stepToEdit: ImtuStep) => {
    // cleanup all the data selected after the destination step.
    // this operation is not automated so there might be a better solution based on step order and step values.
    if (stepToEdit < ImtuStep.Plan) {
      store.setProduct(undefined);
    }
    if (stepToEdit < ImtuStep.Carrier) {
      store.setCarrier(undefined);
    }
    if (stepToEdit < ImtuStep.Phone) {
      store.setRecipientPhone(undefined);
    }
  };

  const handleEditStepAction = (stepToEdit: ImtuStep) => {
    redirectToStep(stepToEdit, true);
  };

  useDidUpdate(() => {
    resetStoreToStep(currentStepIndex);
  }, [currentStepIndex]);

  // ███████╗████████╗███████╗██████╗ ███████╗ ███████╗████████╗ █████╗ ██████╗ ████████╗
  // ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝
  // ███████╗   ██║   █████╗  ██████╔╝███████╗ ███████╗   ██║   ███████║██████╔╝   ██║
  // ╚════██║   ██║   ██╔══╝  ██╔═══╝ ╚════██║ ╚════██║   ██║   ██╔══██║██╔══██╗   ██║
  // ███████║   ██║   ███████╗██║     ███████║ ███████║   ██║   ██║  ██║██║  ██║   ██║
  // ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚══════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝

  const handleCountryStepFinish = ({ countryCode }: { countryCode: CountryCode }) => {
    store.setCountryCode(countryCode);
    redirectToNextStep();
  };

  const handlePhoneStepFinish = ({
    recipientPhoneNumber,
  }: {
    recipientPhoneNumber: string;
  }) => {
    store.setRecipientPhone(recipientPhoneNumber);
    redirectToNextStep();
  };

  const handleCarrierStepFinish = ({ carrier }: { carrier: ImtuCarrierClass }) => {
    store.setCarrier(carrier);
    redirectToNextStep();
  };

  const handlePlanStepFinish = ({ product }: { product: ImtuProductClass }) => {
    store.setProduct(product);
    redirectToNextStep();
  };

  const handleSummaryStepFinish = async ({
    paymentInfo,
    cvvCode,
  }: {
    paymentInfo: PaymentCardClass;
    cvvCode: string;
  }) => {
    store.setPaymentInfo(paymentInfo);
    store.setCvv(cvvCode);

    try {
      if (store.product && store.recipientPhone && store.paymentInfo) {
        await sendImtuStore.sendTransaction({
          recipientPhone: store.recipientPhone,
          product: store.product,
          paymentInfo: store.paymentInfo,
          userCountryCode: userAccountStore.userCountryOfOrigin,
          promoCode: imtuPromoInfoStore.promoInfo?.promoId,
        });
      }
    } finally {
      history.push(`${ROUTE_PATHS.IMTU_RECEIPT}/${sendImtuStore.txnId}`);
    }
  };

  const stepModules: StepModule[] = [];
  stepModules[ImtuStep.Country] = {
    title: t('mobile top-up'),
    component: (
      <Country
        selectedCountryCode={store.countryCode}
        countries={imtuConfigStore.availableCountries}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handleCountryStepFinish}
      />
    ),
  };

  stepModules[ImtuStep.Phone] = {
    title: t('phone number'),
    component: store.countryCode ? (
      <Phone
        phone={store.recipientPhone}
        countryCode={store.countryCode}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handlePhoneStepFinish}
      />
    ) : null,
  };

  stepModules[ImtuStep.Carrier] = {
    title: t('pick a carrier'),
    component: (
      <Carrier
        selectedCarrier={store.carrier}
        recipientPhone={store.recipientPhone || ''}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handleCarrierStepFinish}
      />
    ),
  };

  stepModules[ImtuStep.Plan] = {
    title: t('pick a plan'),
    component: store.carrier && (
      <Plan
        selectedProduct={store.product}
        recipientPhone={store.recipientPhone || ''}
        carrier={store.carrier}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handlePlanStepFinish}
      />
    ),
  };

  stepModules[ImtuStep.Summary] = {
    title: t('top-up summary'),
    component: (
      <Summary
        selectedCardId={store.paymentInfo?.handleId}
        isLoading={sendImtuStore.isLoading || userAccountStore.isLoading}
        amountText={
          imtuPromoInfoStore.promoInfo?.priceTotalDiscountedText ||
          store.product?.senderTotalAmountText ||
          ''
        }
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handleSummaryStepFinish}
        userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
      />
    ),
  };

  // ███████╗████████╗███████╗██████╗ ███████╗ ███████╗███╗   ██╗██████╗
  // ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝ ██╔════╝████╗  ██║██╔══██╗
  // ███████╗   ██║   █████╗  ██████╔╝███████╗ █████╗  ██╔██╗ ██║██║  ██║
  // ╚════██║   ██║   ██╔══╝  ██╔═══╝ ╚════██║ ██╔══╝  ██║╚██╗██║██║  ██║
  // ███████║   ██║   ███████╗██║     ███████║ ███████╗██║ ╚████║██████╔╝
  // ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚══════╝ ╚══════╝╚═╝  ╚═══╝╚═════╝

  return (
    <Spinner isSpinning={!isUiDataReady}>
      <PageTitle
        title={stepModules[currentStepIndex]?.title || ''}
        middleNode={isDesktop && <Stepper {...stepperProps} />}
        buttonsNode={
          <>
            <AdaptiveHelpButton />
            <AdaptiveCancelButton
              onClick={handleCancelClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-cancel-btn`}
            />
          </>
        }
        hasBossLogoLink={isDesktop}
        hasFixedPosition
      />
      {isUiDataReady ? (
        <Grid.Container className="flex flex-col p-4 gap-6 mb-32">
          {!isDesktop && (
            <Grid.Row>
              <Grid.Col className="m-auto" span={{ sm: 12, md: 8 }}>
                <Stepper {...stepperProps} />
              </Grid.Col>
            </Grid.Row>
          )}
          {/* Information on the filled fields */}
          {store.countryCode && (
            <Grid.Row>
              <Grid.Col
                span={{ sm: 12, lg: 4 }}
                offset={{ lg: 4 }}
                className="flex justify-center w-96"
              >
                <ImtuTxnInfoSection
                  countryCode={store.countryCode}
                  recipientPhoneNumber={store.recipientPhone}
                  carrier={store.carrier}
                  product={store.product}
                  promoInfo={imtuPromoInfoStore.promoInfo}
                  isLoading={imtuPromoInfoStore.isLoading}
                  isPromoSectionVisible={currentStepIndex === ImtuStep.Summary}
                  onPromoApply={handlePromoApply}
                  onPromoChange={handlePromoChange}
                  onEditStepSelect={handleEditStepAction}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          {/* Current step module */}
          <Grid.Row>
            <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
              <Switch>
                {availableSteps.map((step) => {
                  const module = stepModules[step];
                  return (
                    <Route
                      key={stepPaths[step].toLowerCase()}
                      path={`${basePath}${stepPaths[step]}`}
                      render={() => {
                        if (!module.component) {
                          redirectToNextStep();
                        }
                        return module.component;
                      }}
                    />
                  );
                })}
              </Switch>
            </Grid.Col>
          </Grid.Row>

          {/* Promotions if available */}
          {promosToDisplay.length > 0 && (
            <Grid.Row>
              <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
                <SectionContainer>
                  <SectionHeader>{t('mobile top-up promotions')}</SectionHeader>
                  <ImtuPromosSection data={promosToDisplay} />
                </SectionContainer>
              </Grid.Col>
            </Grid.Row>
          )}

          {/* Terms and Conditions */}
          {currentStepIndex === ImtuStep.Summary && userAccountStore.currentLanguage && (
            <Grid.Row>
              <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
                <SectionContainer>
                  <ImtuTermsBlock
                    countryCode={userAccountStore.userCountryOfOrigin}
                    languageLocale={userAccountStore.currentLanguageLocale}
                    className="mt-auto"
                  />
                </SectionContainer>
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid.Container>
      ) : null}

      {/* Imtu availability modal */}
      <Modal
        isOpen={store.isImtuAvailabilityModalVisible}
        onRequestClose={handleImtuAvailabilityModalClose}
        header={<Logo className="w-24" />}
      >
        {t(
          'We are sorry. At this time BOSS Revolution does not provide this service in your country.',
        )}
      </Modal>
    </Spinner>
  );
};

export default observer(InternationalMobileTopUp);
