import { FC, useState } from 'react';

import BrSidebarMenu from '@components/modules/BrSidebarMenu';
import BrMobileNavigationMenu from '@components/modules/BrMobileNavigationMenu';
import ThemeInjector from '@components/modules/Theme/ThemeInjector';

import { normalizeStringCompound } from '@utils/string';

import { MediaMatcher } from '@components/common';
import BrHeaderNavigation from '@components/common/BrHeaderNavigation';

import HeaderContainer from './HeaderContainer';

import './styles.scss';

const BrTemplateWithSidebar: FC = (props) => {
  const { children } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-color/background pt-[48px] lg:pt-[72px] min-h-screen">
      <ThemeInjector isBrTheme />
      <HeaderContainer
        className={normalizeStringCompound([
          'backdrop-blur-sm backdrop-filter',
          isMenuOpen ? 'bg-color/background' : 'bg-color/background/75',
        ])}
      >
        <div className="br-container">
          <BrHeaderNavigation
            onToggleButtonClick={handleMenuToggle}
            isToggleButtonToggled={isMenuOpen}
          />
        </div>
      </HeaderContainer>
      <MediaMatcher isNotDesktop>
        <BrMobileNavigationMenu isOpen={isMenuOpen} />
      </MediaMatcher>
      <div className="br-container pt-middle lg:pt-default">
        <div className="br-row">
          <MediaMatcher isDesktop>
            <div className="br-col basis-[var(--br-sidebar-width)] pl-xlarge pr-0 ">
              <div className="sticky top-[88px]">
                <BrSidebarMenu />
              </div>
            </div>
          </MediaMatcher>
          <div className="br-col flex-grow px-xlarge">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BrTemplateWithSidebar;
