import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import BrCardFunctionality, {
  BrCardButtonProps,
} from '@components/common/BrCardFunctionality';
import BrFavoriteNumberForm from '@components/common/BrFavoriteNumberForm';

interface Props {
  countryCode: CountryCode;
  maxPhoneNumbers: number;
  phoneNumbers?: string[];
  isLoading: boolean;
  onPhoneSubmit(phoneNumber: string): void;
  onPhoneDelete(phoneNumber?: string): void;
  onPhoneEdit({
    oldPhoneNumber,
    newPhoneNumber,
  }: {
    oldPhoneNumber: string;
    newPhoneNumber: string;
  }): void;
}

const PHONE_DEFAULT_VALUE = '';

const isPhoneFilled = (phoneNumber: string) => {
  return phoneNumber !== PHONE_DEFAULT_VALUE;
};

const BrCallingPlansFavoriteNumberCard: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    countryCode,
    maxPhoneNumbers,
    phoneNumbers = [],
    onPhoneSubmit,
    onPhoneEdit,
    onPhoneDelete,
    isLoading,
  } = props;

  const { t } = useTranslation();

  const [phoneNumbersLocal, setPhoneNumbersLocal] = useState<string[]>(phoneNumbers);

  useEffect(() => {
    setPhoneNumbersLocal(phoneNumbers);
  }, [phoneNumbers]);

  const hasAvailableContactSlots = maxPhoneNumbers > phoneNumbersLocal.length;
  const hasUnfinishedPhone = phoneNumbersLocal.some((item) => {
    return item === PHONE_DEFAULT_VALUE;
  });
  const hasAddedPhone = phoneNumbersLocal.some(isPhoneFilled);

  const handleAddPhoneClick = () => {
    if (hasAvailableContactSlots && !hasUnfinishedPhone) {
      setPhoneNumbersLocal([...phoneNumbersLocal, PHONE_DEFAULT_VALUE]);
    }
  };

  const addTextDefault = t('Add the phone number');
  const addTextAdditional = t('Add another phone number');
  const addText = hasAddedPhone ? addTextAdditional : addTextDefault;

  const addButtonCfg: BrCardButtonProps = useMemo(() => {
    return {
      cmpType: 'link',
      size: 'small',
      text: addText,
      iconName: 'add',
      hasIconLeft: true,
      btnAlign: 'end',
      btnTooltipCfg: {
        place: 'bottom',
        content: t('Please provide a phone number'),
      },
      onClick: handleAddPhoneClick,
    };
  }, [addText, t, handleAddPhoneClick]);

  const handlePhoneDelete = (phoneNumber: string) => {
    if (phoneNumber === PHONE_DEFAULT_VALUE) {
      setPhoneNumbersLocal(phoneNumbersLocal.filter(Boolean));
    } else {
      onPhoneDelete(phoneNumber);
    }
  };

  const singularDescriptionText = t('You can add up to 1 favorite number');
  const pluralDescriptionText = t(
    'You can add up to {{maxPhoneNumbers}} favorite numbers.',
    {
      maxPhoneNumbers,
    },
  );
  const descriptionText =
    maxPhoneNumbers === 1 ? singularDescriptionText : pluralDescriptionText;

  return (
    <BrCardFunctionality
      title={t('Favorite Calling Numbers')}
      iconName="heart"
      iconColor="text-color/attention"
      text={descriptionText}
      buttons={
        hasAvailableContactSlots && !hasUnfinishedPhone ? [addButtonCfg] : undefined
      }
    >
      {phoneNumbersLocal.map((item) => {
        return (
          <BrFavoriteNumberForm
            key={item}
            phoneNumber={item || PHONE_DEFAULT_VALUE}
            countryCode={countryCode}
            phoneNumbers={phoneNumbers}
            onPhoneSubmit={onPhoneSubmit}
            onPhoneDelete={handlePhoneDelete}
            onPhoneEdit={onPhoneEdit}
            isLoading={isLoading}
          />
        );
      })}
    </BrCardFunctionality>
  );
};

export default BrCallingPlansFavoriteNumberCard;
