import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import CountryCode from '@root/interfaces/CountryCode';

import { Button, Divider, Typography } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import { ReactComponent as StatusSuccess } from '@src/static/assets/images/status-success.svg';

import InfoItem from './InfoItem';
import HelpModal from './HelpModal';

import './styles.scss';

interface Props {
  currencySymbol: string;
  userCountryOfOrigin: CountryCode;
  currency: string;
  rechargeAmount: number;
  date: Date;
  promoAmount?: number;
  cardNumber?: string;
  newBalance?: number;
  oldBalance?: number;
  className?: string;
}

const Receipt: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    currencySymbol,
    userCountryOfOrigin,
    currency,
    oldBalance,
    newBalance,
    rechargeAmount,
    promoAmount,
    date,
    cardNumber,
    className,
  } = props;

  const { t } = useTranslation();

  const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  const containerClassNames = normalizeStringCompound([
    'px-5 py-12 border border-gray-300 border-b-0 relative',
    className,
  ]);

  const handleHelpTextClick = () => {
    setHelpModalOpen(true);
  };

  const handleHelpDialogCloseClick = () => {
    setHelpModalOpen(false);
  };

  return (
    <>
      <div className={containerClassNames}>
        <div>
          <div className="text-center">
            <StatusSuccess className="text-green-400 mx-auto w-10 h-10 mb-3" />
            <Typography.Title level={3}>{t('Thank you!')}</Typography.Title>
            <p className="text-2xl">{t('We appreciate your purchase')}</p>
          </div>
          <Divider className="mb-7" />
          {Boolean(oldBalance) && (
            <InfoItem
              title={`${t('Previous balance')}:`}
              data={`${currencySymbol}${oldBalance} ${currency}`}
            />
          )}
          <InfoItem
            title={`+ ${t('Recharge amount')}:`}
            data={`${currencySymbol}${rechargeAmount} ${currency}`}
          />
          {Boolean(promoAmount) && (
            <InfoItem
              title={`+ ${t('Promo amount')}:`}
              data={`${currencySymbol}${promoAmount} ${currency}`}
            />
          )}
          {Boolean(newBalance) && (
            <InfoItem
              title={`${t('New balance')}:`}
              data={`${currencySymbol}${newBalance} ${currency}`}
              isLast
            />
          )}
          <Divider className="mb-7" />
          <InfoItem title={`${t('Transaction time')}:`} data={format(date, 'hh:mma')} />
          <InfoItem
            title={`${t('Date')}:`}
            data={format(date, 'MM.dd.yyyy')}
            isSecureContent
          />
          {Boolean(cardNumber) && (
            <InfoItem
              title={`${t('Payment method')}:`}
              data={cardNumber}
              isLast
              isSecureContent
            />
          )}

          <div className="bottom-border" />
        </div>
      </div>
      <div className="text-center p-4 m-4">
        <Button shape="text" onClick={handleHelpTextClick}>
          {t('See how to make a call')}
        </Button>
      </div>
      <HelpModal
        countryCode={userCountryOfOrigin}
        isOpen={isHelpModalOpen}
        onRequestClose={handleHelpDialogCloseClick}
      />
    </>
  );
};

export default Receipt;
