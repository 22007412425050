import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';
import { BrAdvancedInputProps } from '@root/interfaces/components/BrAdvancedInput';

import BrButton from '@components/common/BrButton';
import BrAdvancedInput from '@components/common/BrAdvancedInput';

// TODO: move to common place when payment cards are done
import { ReactComponent as CvvTip } from '@src/static/assets/images/cvv-tip.svg';
import { ReactComponent as VisaIcon } from '@src/components/common/BrPaymentCardSelect/images/visa.svg';
import { ReactComponent as MasterCardIcon } from '@src/components/common/BrPaymentCardSelect/images/mastercard.svg';
import { ReactComponent as DiscoverIcon } from '@src/components/common/BrPaymentCardSelect/images/discover.svg';
import { ReactComponent as AmExIcon } from '@src/components/common/BrPaymentCardSelect/images/american-express.svg';

import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

import { CVV_INPUT_MASK } from '@root/constants/inputMasks';
import {
  CARD_CVV_MAX_LENGTH,
  CARD_CVV_MIN_LENGTH,
} from '@root/constants/paymentCards/paymentCardsData';

interface Props {
  cardNumber: string;
  cardIssuerType?: PaymentCardIssuerKeys;
  onSubmit(cvvValue: string): void;
}

type IconCfg = Pick<BrAdvancedInputProps, 'iconRight' | 'iconRightClassNames'>;
const ICON_CLEAR_CFG: IconCfg = {
  iconRight: 'close_-_x_-_times_fill',
  iconRightClassNames: 'text-support-colors/highlights',
};

const iconsMap: Record<PaymentCardIssuerKeys, React.ReactNode> = {
  MC: <MasterCardIcon />,
  V: <VisaIcon />,
  AE: <AmExIcon />,
  D: <DiscoverIcon />,
};

const BrCvvForm: React.FunctionComponent<Props> = (props: Props) => {
  const { cardNumber, cardIssuerType, onSubmit } = props;

  const { t } = useTranslation();

  const [cvvNumber, setCvvNumber] = useState<string>('');
  const [isShowMeWhereSectionExpanded, setShowMeWhereSectionExpanded] = useState<boolean>(
    false,
  );

  const handleShowMeWhereButtonClick = () => {
    setShowMeWhereSectionExpanded(!isShowMeWhereSectionExpanded);
  };

  const handleCvvNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCvvNumber(e.target.value);
  };

  const handleCvvNumberSubmit = () => {
    onSubmit(cvvNumber);
  };

  const handleClearCvv = () => {
    setCvvNumber('');
  };

  // TODO: think on reuse when payment cards are done. There is similar section for cvv
  return (
    <div className="flex flex-col space-y-default">
      <div className="text-body/callout/default">
        {t('We need your security code that is on the back of your card.')}
      </div>
      <div className="flex items-center space-x-default">
        <div className="flex items-center w-full space-x-xsmall">
          <div className="w-xlarge">{cardIssuerType && iconsMap[cardIssuerType]}</div>
          <div className="text-body/primary/default text-support-colors/highlights">
            {cardNumber}
          </div>
        </div>
        <BrButton
          text="Show me where"
          cmpType="link"
          size="xSmall"
          hasIconRight
          iconName={isShowMeWhereSectionExpanded ? 'chevron-up' : 'chevron-down'}
          onClick={handleShowMeWhereButtonClick}
        />
      </div>
      {isShowMeWhereSectionExpanded && (
        <div className="flex justify-center py-xsmall">
          <CvvTip />
        </div>
      )}
      <InputMask
        mask={CVV_INPUT_MASK}
        maskChar=""
        value={cvvNumber}
        onChange={handleCvvNumberChange}
      >
        {() => (
          <BrAdvancedInput
            onWhat="surface"
            topLabel={t('Security code')}
            inputMode="numeric"
            type="password"
            placeholder={t('Enter the security code')}
            {...(cvvNumber ? ICON_CLEAR_CFG : undefined)}
            onIconClickRight={handleClearCvv}
          />
        )}
      </InputMask>
      <BrTooltipWrapper
        cfg={{
          place: 'top',
          content: t('Please provide a security code'),
        }}
      >
        <BrButton
          text={t('Submit')}
          disabled={
            cvvNumber.length < CARD_CVV_MIN_LENGTH ||
            cvvNumber.length > CARD_CVV_MAX_LENGTH
          }
          onClick={handleCvvNumberSubmit}
        />
      </BrTooltipWrapper>
    </div>
  );
};

export default BrCvvForm;
