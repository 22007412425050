import { CallingPlanOrderStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import BrOrderStatusIllustration from '../BrOrderStatusIllustration';
import BrCard from '../BrCard';

interface Props {
  status: CallingPlanOrderStatus;
  title: string;
  subTitle?: React.ReactNode;
}

const BrOrderStatus = (props: Props) => {
  const { status, title, subTitle } = props;

  return (
    <BrCard variant="without-bg">
      <div className="flex flex-col items-center text-center">
        <BrOrderStatusIllustration className="w-[149px] h-[149px]" status={status} />
        <div className="text-headers/header-2 text-on-colors/on-primary mt-small">
          {title}
        </div>
        {subTitle && (
          <div className="mt-xsmall text-support-colors/highlights text-body/primary/default">
            {subTitle}
          </div>
        )}
      </div>
    </BrCard>
  );
};

export default BrOrderStatus;
