import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import BrOrderStatusIllustration from '@components/common/BrOrderStatusIllustration';

interface Props {
  className?: string;
}

const ReceiptHeader = (props: Props) => {
  const { className } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound(['text-center ', className])}>
      <BrOrderStatusIllustration
        className="w-[149px] h-[149px] m-auto mb-small"
        status="receipt"
      />
      <div className="text-headers/header-2 mb-xsmall">{t('Thank you')}</div>
      <div className="text-body/primary/default text-support-colors/highlights">
        {t('Your receipt')}
      </div>
    </div>
  );
};

export default ReceiptHeader;
