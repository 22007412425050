import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  value?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  bonusPercentage?: number;
}

const AutoRechargeToggle: FC<React.PropsWithChildren<Props>> = (props) => {
  const { value = false, onChange, className, bonusPercentage, dataTestId } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    onChange(!value);
  };

  const containerClassNames = normalizeStringCompound([
    'px-1 rounded-full w-32 h-18 flex items-center mx-auto',
    value ? 'bg-green-200 justify-end' : 'bg-gray-400 justify-start',
  ]);

  const toggleClassNames = normalizeStringCompound([
    'rounded-full w-16 h-16 bg-white flex items-center justify-center uppercase text-xl font-bold',
    value ? 'text-green-200' : 'text-gray-400',
  ]);

  return (
    <div className={className}>
      <button
        onClick={handleClick}
        className={containerClassNames}
        data-test-id={dataTestId}
      >
        <div className={toggleClassNames}>{value ? 'On' : 'Off'}</div>
      </button>
      <p className="mt-4 text-3xl font-bold text-center first-letter:uppercase">
        {value ? (
          <div className="text-green-200">
            {bonusPercentage && (
              <span>
                {t('{{value}}% bonus active', {
                  value: bonusPercentage,
                })}
                <>,</>
              </span>
            )}
            <span className="block">{t('auto-recharge is on')}</span>
          </div>
        ) : (
          <div className="text-red-400">
            {bonusPercentage && <span>{t('No bonus')},</span>}
            <span className="block">{t('auto-recharge is off')}</span>
          </div>
        )}
      </p>
    </div>
  );
};

export default AutoRechargeToggle;
