import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import ROUTE_PATHS from '@root/routes/paths';

import { AppConfigStore } from '@root/stores';

import useStore from '@root/hooks/useStore';

import { IconNames } from '@root/interfaces/components/Icon';

import BrMenu from '@components/common/BrMenu';
import { MenuItemProps } from '@components/common/BrMenu/MenuItem';

const BrSidebarMenu: React.FC = () => {
  const { t } = useTranslation();

  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const data: MenuItemProps[] = [
    {
      id: 'Home',
      title: t('Home'),
      iconName: 'home-outline',
      menuUrl: ROUTE_PATHS.ACCOUNT,
    },
    {
      id: 'Mobile Top-Up',
      title: t('Mobile Top-Up'),
      iconName: 'top-up-outline',
      menuUrl: ROUTE_PATHS.IMTU,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isImtuAvailable,
    },
    {
      id: 'Calling Plans',
      title: t('Calling Plans'),
      iconName: 'plans-outline',
      menuUrl: ROUTE_PATHS.CALLING_PLANS,
      highlightedUrls: [
        ROUTE_PATHS.CALLING_PLANS,
        ROUTE_PATHS.CALLING_PLAN_RECEIPT,
        ROUTE_PATHS.CALLING_PLAN_COMPLETED_ORDER,
        ROUTE_PATHS.CALLING_AUTO_RECHARGE,
        ROUTE_PATHS.CALLING_RECHARGE_RECEIPT,
      ],
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isCallingPlansAvailable,
    },
    {
      id: 'Calling Recharge',
      title: t('Calling Recharge'),
      iconName: 'recharge',
      menuUrl: ROUTE_PATHS.CALLING,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isCallingAvailable,
    },
    {
      id: 'Money Transfer',
      title: t('Money Transfer'),
      iconName: 'money-outline',
      menuUrl: ROUTE_PATHS.MT_MODAL,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isMtAvailable,
    },
    {
      id: 'Transaction history',
      title: t('Transaction history'),
      iconName: 'time-outline',
      menuUrl: ROUTE_PATHS.TRANSACTIONS_HISTORY,
    },
  ]
    .filter((item) => {
      return !item.isDisabled;
    })
    .map((item) => {
      const pathFirstLevel = `/${window.location.pathname.split('/')[1]}`;
      return {
        ...item,
        iconName: item.iconName as IconNames,
        isActive:
          window.location.pathname === item.menuUrl ||
          item.highlightedUrls?.includes(pathFirstLevel),
      };
    });

  return <BrMenu data={data} />;
};

export default observer(BrSidebarMenu);
