import { UserAccountStore } from '@root/stores';
import { useHistory } from 'react-router-dom';

import useStore from '@root/hooks/useStore';

import { IconNames } from '@root/interfaces/components/Icon';

import UserLanguageSelect from '@components/modules/UserLanguageSelect';
import UserAvatarMenu from '@components/modules/UserAvatarMenu';

import { MediaMatcher } from '@components/common';
import BrButton from '@components/common/BrButton';
import LogotypeLink from '@components/common/BrLogotypeLink';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

import { normalizeStringCompound } from '@utils/string';

import ToggleButton from './components/ToggleButton';

import usePageTitle from './usePageTitle';

interface Props {
  iconName?: IconNames;
  isToggleButtonToggled?: boolean;
  onToggleButtonClick(): void;
}

// TODO: move to modules since it contains business logic now
const BrHeaderNavigation = (props: Props) => {
  const { isToggleButtonToggled, onToggleButtonClick } = props;

  const history = useHistory();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const fallbackLocale = getFallbackLocaleForLegalUrls(
    userAccountStore.currentLanguageLocale,
  );
  const { pageTitle } = usePageTitle();

  const handleBackClick = async () => {
    history.goBack();
  };

  const functionalItemsWrapperClassNames = normalizeStringCompound([
    'w-full flex items-center',
    pageTitle ? 'justify-between' : 'justify-end',
  ]);

  return (
    <>
      <MediaMatcher isDesktop>
        <div className="flex items-center px-0 space-x-small py-small pt-default pb-small">
          <LogotypeLink
            className="flex-shrink-0 my-xsmall py-xsmall w-[200px]"
            languageLocale={fallbackLocale}
            target="_blank"
          />
          <div className={functionalItemsWrapperClassNames}>
            {pageTitle && (
              <>
                <BrButton
                  cmpStyle="circle"
                  cmpType="text"
                  iconName="back_-_left"
                  text={pageTitle}
                  onClick={handleBackClick}
                />
                <div className="mr-auto text-body/callout/default ml-xsmall">
                  {pageTitle}
                </div>
              </>
            )}

            <div className="flex flex-row items-center space-x-default">
              <UserLanguageSelect />
              <UserAvatarMenu />
            </div>
          </div>
        </div>
      </MediaMatcher>

      <MediaMatcher isNotDesktop>
        <div className="flex justify-between items-center space-x-xxsmall py-xxsmall">
          {pageTitle ? (
            <>
              <BrButton
                cmpStyle="circle"
                cmpType="text"
                iconName="back_-_left"
                text={pageTitle}
                className="transform -translate-x-default"
                onClick={handleBackClick}
              />
              <div className="flex items-center text-center justify-center text-body/callout/default">
                {pageTitle}
              </div>
            </>
          ) : (
            <LogotypeLink
              className="h-xxxlarge w-[200px]"
              languageLocale={fallbackLocale}
              target="_blank"
            />
          )}

          <div className="ml-auto flex items-center">
            <ToggleButton
              isToggled={isToggleButtonToggled}
              onChange={onToggleButtonClick}
              className="transform translate-x-default"
            />
          </div>
        </div>
      </MediaMatcher>
    </>
  );
};

export default BrHeaderNavigation;
