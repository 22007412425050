import { useTranslation } from 'react-i18next';

import BrButton from '@components/common/BrButton';

import { PaymentCardFundingType } from '@root/constants/moneyTransfer/paymentCards';

interface Props {
  onAdd(): void;
  onUpdate(): void;
  maskedNumber: string;
  fundingType: PaymentCardFundingType;
}

const BrExpiredPaymentCardUpdateUi = (props: Props) => {
  const { onAdd, onUpdate, maskedNumber, fundingType } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="text-body/primary/default mb-default space-y-middle">
        <p>
          {t('Your {{fundingType}} card {{maskedNumber}} has expired', {
            fundingType,
            maskedNumber,
          })}
        </p>
        <p>{t('Please update your card or add a new one to proceed.')}</p>
      </div>
      <div className="flex space-x-default">
        <BrButton
          className="flex-grow basis-1/2"
          cmpType="gray"
          onClick={onAdd}
          text={t('Add new')}
        />
        <BrButton className="flex-grow basis-1/2" onClick={onUpdate} text={t('Update')} />
      </div>
    </>
  );
};

export default BrExpiredPaymentCardUpdateUi;
