import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BASE_PATHS from '@root/routes/paths';

// Didn't find elegant way to use nested paths as variables.
// It will lead to importing the stuff from modules
const pathToTitleMap: Record<string, string> = {
  '/': '', // you can use this for testing in storybook, but do not forget to delete test name
  // Calling plans
  [BASE_PATHS.CALLING_PLANS]: 'Calling plans', // t('Calling Plans'),
  [BASE_PATHS.CALLING_PLAN_RECEIPT]: 'Receipt', // t('Receipt'),
  [`${BASE_PATHS.CALLING_PLANS}/setup`]: 'Select calling plan', // t('Select calling plan'),
  [`${BASE_PATHS.CALLING_PLANS}/summary`]: 'Order confirmation', // t('Order confirmation'),
  [`${BASE_PATHS.CALLING_PLANS}/manage`]: 'Plan management', // t('Order confirmation'),
  [BASE_PATHS.CALLING]: 'Recharge balance', // an example for future titles
  [BASE_PATHS.TRANSACTIONS_HISTORY]: 'Transaction history', // t('Transaction history')
  [BASE_PATHS.ACCOUNT_PROFILE]: 'My Profile', // t('My Profile')
};

const usePageTitle = (): { pageTitle: string | undefined } => {
  const location = useLocation();
  const { t } = useTranslation();

  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    setPageTitle(t(pathToTitleMap[location.pathname]));
  }, [location, t]);

  return { pageTitle };
};

export default usePageTitle;
