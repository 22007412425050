import React from 'react';

import ServiceTemplate from '@components/templates/ServiceTemplate';

import CallingAutoRecharge from '@components/modules/CallingAutoRecharge';

const CallingAutoRechargePage = () => {
  return (
    <ServiceTemplate>
      <CallingAutoRecharge />
    </ServiceTemplate>
  );
};

export default CallingAutoRechargePage;
