import React, {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import BrCardFunctionality from '@components/common/BrCardFunctionality';
import BrBadge from '../BrBadge';
import BrButton, { BrButtonProps } from '../BrButton';
import BrCard from '../BrCard';
import BrAlert from '../BrAlert';
import BrRecipientNumberForm from './BrRecipientNumberForm';
import BrExpandableContainer from '../BrAnimateHeightContainer';

type BundledProduct = {
  title: string;
  descriptionShort?: string;
  descriptionLong: string;
};

type ActionType = 'manage' | 'purchase';
const ACTION_TYPE_BUTTON_STYLE: Record<ActionType, BrButtonProps['cmpType']> = {
  manage: 'light',
  purchase: 'solid',
};

interface Props {
  id: string;
  actionText?: string;
  actionType?: ActionType;
  planTypeText: string;
  title: string;
  descriptionShort?: string;
  functionalAddon?: ReactNode;
  isNonPurchasable?: boolean;
  isCombo?: boolean;
  isDetailsOpen?: boolean;
  bundledProduct?: BundledProduct;
  expirationContent?: React.ReactNode;
  unavailableContent?: string;
  recipientFormCfg?: ComponentProps<typeof BrRecipientNumberForm>;
  onActionBtnClick?(id: string): void;
  onEditBtnClick?(): void;
}

const BrCallingPlan: FC<PropsWithChildren<Props>> = (props) => {
  const {
    id,
    actionText,
    actionType,
    planTypeText,
    children,
    title,
    descriptionShort,
    bundledProduct,
    functionalAddon,
    isNonPurchasable,
    isCombo,
    isDetailsOpen,
    expirationContent,
    unavailableContent,
    recipientFormCfg,
    onActionBtnClick,
    onEditBtnClick,
  } = props;

  const [isDetailsOpened, setIsDetailsOpened] = useState(isDetailsOpen);

  const { t } = useTranslation();

  useEffect(() => {
    setIsDetailsOpened(isDetailsOpen);
  }, [isDetailsOpen]);

  const handleOnDetailsClick = () => {
    setIsDetailsOpened((isOpen) => !isOpen);
  };

  const handleOnActionButtonClick = () => {
    onActionBtnClick?.(id);
  };

  return (
    <BrCard>
      <div className="space-y-middle">
        <div className="flex justify-between items-center">
          <div className="flex space-x-small">
            <BrBadge classType="neutral" styleType="less-contrast" text={planTypeText} />
            {isCombo && (
              <BrBadge
                iconLeft="sparkles"
                styleType="less-contrast"
                classType="attention"
                text={t('Combo plan')}
              />
            )}
          </div>
          {onEditBtnClick && (
            <BrButton
              cmpType="link"
              text={t('Edit')}
              className="!py-0 !pr-0 !leading-0"
              onClick={onEditBtnClick}
            />
          )}
        </div>
        <div className="flex items-center justify-between space-x-middle">
          <button
            disabled={isNonPurchasable}
            onClick={handleOnActionButtonClick}
            className="text-body/primary/demibold flex-1 flex text-left tap-highlight-transparent"
          >
            {title}
          </button>
          {onActionBtnClick && (
            <BrButton
              size="small"
              onClick={handleOnActionButtonClick}
              text={actionText}
              className="w-[104px]"
              cmpType={
                actionType && ACTION_TYPE_BUTTON_STYLE[actionType]
                  ? ACTION_TYPE_BUTTON_STYLE[actionType]
                  : 'solid'
              }
              disabled={isNonPurchasable}
            />
          )}
        </div>
        {/* Details section */}
        {children && (
          <>
            <div className="border-t border-solid border-t-support-colors/lines" />
            <div className="flex flex-row items-center justify-between">
              <div className="text-support-colors/highlights text-body/footnote/default">
                {descriptionShort}
              </div>
              <div>
                <BrButton
                  cmpType="text"
                  size="xSmall"
                  text={t('Details')}
                  iconName={isDetailsOpened ? 'up_-_top' : 'bottom_-_down'}
                  hasIconRight
                  onClick={handleOnDetailsClick}
                />
              </div>
            </div>
            <div className="!m-0">
              <BrExpandableContainer isExpanded={isDetailsOpened}>
                {children}
              </BrExpandableContainer>
            </div>
          </>
        )}
        {/* Combo plan bundled Imtu product */}
        {bundledProduct && (
          <div className="mt-middle">
            <BrCardFunctionality
              iconName="sparkles"
              title={bundledProduct.title}
              text={
                isDetailsOpened
                  ? `${bundledProduct.descriptionShort}\n${bundledProduct.descriptionLong}`
                  : bundledProduct.descriptionShort
              }
            >
              {recipientFormCfg ? (
                <BrRecipientNumberForm
                  onPhoneChange={recipientFormCfg.onPhoneChange}
                  externalValidationFn={recipientFormCfg.externalValidationFn}
                  onValidation={recipientFormCfg.onValidation}
                />
              ) : null}
            </BrCardFunctionality>
          </div>
        )}
        {/* Favorite number form etc. */}
        {functionalAddon}
        {expirationContent && (
          <BrAlert classType="warning-variant" className="w-full !whitespace-normal">
            {expirationContent}
          </BrAlert>
        )}
        {unavailableContent && (
          <BrAlert classType="neutral" className="w-full" text={unavailableContent} />
        )}
      </div>
    </BrCard>
  );
};

export default BrCallingPlan;
