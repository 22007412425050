import BrBadge from '@components/common/BrBadge';

import Circle from './Circle';

interface Props {
  productTitle: string;
  productType?: string; // we don't have this info for now
  className?: string;
}

const TopSection = (props: Props) => {
  const { productType, productTitle } = props;

  return (
    <div className="relative py-xlarge px-[52px] text-center">
      {productType && <BrBadge classType="neutral" text={productType} />}
      <div className="text-body/callout/default mt-middle">{productTitle}</div>
      <Circle classNames="absolute left-0 -translate-x-1/2" />
      <Circle classNames="absolute right-0 translate-x-1/2" />
    </div>
  );
};

export default TopSection;
