/* 
 This is the reviewed implementation of the OptionList. No meaningfull changes in this file except it was renamed. 
 I needed to rename it in order to separate implementation without virtual list that is used in BrDrawer right now
*/
import { FixedSizeList as VirtualList } from 'react-window';
import SimpleBar from 'simplebar-react';

import { OptionListProps } from '@root/interfaces/components/Select';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import Option from './Option';
import Scrollbar from '../Scrollbar';

type RenderItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (option: BrOptionProps) => void;
};

type Props = OptionListProps & {
  dropdownHeight: number;
  listRef: React.MutableRefObject<React.MutableRefObject<SimpleBar> | null>;
  options: BrOptionProps[];
  selectedOption?: BrOptionProps;
  handleOnOptionSelect?(option: BrOptionProps): Promise<undefined>;
  renderItem?(item: RenderItem, itemIdx: number): JSX.Element;
};

const VirtualOptionList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    options,
    dataTestId,
    listRef,
    dropdownHeight,
    selectedOption,
    onSelect,
    renderItem,
  } = props;

  return (
    <Scrollbar autoHide style={{ height: dropdownHeight }}>
      {({ scrollableNodeRef, contentNodeRef }) => {
        listRef.current = scrollableNodeRef;

        return (
          <VirtualList
            itemData={options}
            itemCount={options.length}
            className="[scrollbar-width:none]"
            itemSize={44}
            height={dropdownHeight}
            outerRef={scrollableNodeRef}
            innerRef={contentNodeRef}
            // there is a lib's related type issue, for now I just put ts-ignore
            // @ts-ignore
            ref={listRef}
          >
            {({ data, index, style }) => {
              const item = data[index];
              const isActive = selectedOption?.value === item.value;

              return (
                <div style={style}>
                  {renderItem ? (
                    renderItem(
                      {
                        option: item,
                        isActive,
                        onSelect,
                      },
                      index,
                    )
                  ) : (
                    <Option
                      key={item.value}
                      onClick={onSelect}
                      dataTestId={`${dataTestId}-${item.value}`}
                      isActive={isActive}
                      {...item}
                    />
                  )}
                </div>
              );
            }}
          </VirtualList>
        );
      }}
    </Scrollbar>
  );
};

export default VirtualOptionList;
