import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import Icon from '@components/common/Icon';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import BrPaymentCardIssuerImg from '../BrPaymentCardIssuerImg';

interface Props {
  isSelected?: boolean;
  cardType: string;
  maskedNumber: string;
  className?: string;
  paySource: PaymentCardIssuerKeys;
  onEditClick?(value: string): void;
  isExpired?: boolean;
}

const BrOption = (props: Props) => {
  const {
    cardType,
    maskedNumber,
    className,
    paySource,
    onEditClick,
    isSelected,
    isExpired,
  } = props;

  const { t } = useTranslation();

  const handleOnEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditClick?.('');
  };

  return (
    <div
      className={normalizeStringCompound([
        'flex bg-color/surface items-center space-x-middle min-h-[52px]',
        className,
      ])}
    >
      <div className="w-large h-large flex-shrink-0">
        {isSelected && (
          <Icon
            name={isExpired ? 'close' : 'checkmark-circle'}
            width={20}
            height={20}
            className={normalizeStringCompound([
              isExpired ? 'text-color/error' : 'text-color/success',
            ])}
          />
        )}
      </div>
      <BrPaymentCardIssuerImg paySource={paySource} className="w-xlarge flex-shrink-0" />
      <div className="w-full text-left">
        <div
          className={normalizeStringCompound([
            'flex flex-grow text-headers/header-6 space-x-xsmall',
            isExpired ? 'text-on-colors/on-primary-variant' : '',
          ])}
        >
          <div>{cardType}</div>
          <div>{maskedNumber}</div>
        </div>
        {isExpired && <span className="text-color/error">{t('Card is expired')}</span>}
      </div>
      <button className="w-[48px]" onClickCapture={handleOnEditClick}>
        <Icon name="edit" />
      </button>
    </div>
  );
};

export default BrOption;
