import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserAccountStore } from '@root/stores';
import FundingHistoryStore from '@root/stores/FundingHistoryStore';
import CallingRechargeStore from '@root/stores/CallingRechargeStore';
import PaymentCardStore from '@root/stores/PaymentCardStore';

import BASE_PATHS from '@root/routes/paths';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import CallingBalanceInfo from '@components/common/CallingBalanceInfo';
import CallingAutoRechargePromoInfo from '@components/common/CallingAutoRechargePromoInfo';

import {
  Grid,
  AdaptiveCancelButton,
  AdaptiveHelpButton,
  PageTitle,
  Spinner,
  Button,
} from '@components/common';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import Receipt from './components/Receipt';

import useCallingRechargeTxnParams from './useCallingRechargeTxnParams';

interface Props {
  txnId: string;
}

const CallingRechargeReceipt: React.FunctionComponent<Props> = (props: Props) => {
  const { txnId } = props;

  const history = useHistory();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const fundingHistoryStore = useLocalStore(() => new FundingHistoryStore());
  const paymentCardStore = useLocalStore(() => new PaymentCardStore());
  const store = useLocalStore(() => new CallingRechargeStore());

  const txnParams = useCallingRechargeTxnParams();

  useDidMount(async () => {
    await fundingHistoryStore.getTransactions();
    await store.getCallingInfo();
    if (txnParams?.cardHandleId) {
      await paymentCardStore.getEditableCardByHandleId(txnParams?.cardHandleId);
    }
  });

  const txn = fundingHistoryStore.getTxnById(txnId);

  const handleCancelButtonClick = () => {
    history.push(BASE_PATHS.CALLING);
  };

  const handleManageArClick = () => {
    history.push(BASE_PATHS.CALLING_AUTO_RECHARGE);
  };

  return (
    <Spinner
      isSpinning={
        userAccountStore.isLoading ||
        fundingHistoryStore.isLoading ||
        paymentCardStore.isLoading
      }
    >
      <PageTitle
        title={t('recharge balance')}
        buttonsNode={
          <>
            <AdaptiveHelpButton />
            <AdaptiveCancelButton onClick={handleCancelButtonClick} />
          </>
        }
        hasBossLogoLink={isDesktop}
        hasFixedPosition
      />

      <div className="mt-10 mb-24">
        <Grid.Container>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} offset={{ md: 3, lg: 4 }}>
              {txn && (
                <>
                  {txnParams?.newBalance && (
                    <CallingBalanceInfo
                      title={t('Your new balance is')}
                      currencySymbol={txn.currencySymbol}
                      currency={txn.currency}
                      amount={txnParams.newBalance}
                      className="mb-4"
                    />
                  )}
                  <Receipt
                    currencySymbol={txn.currencySymbol}
                    userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
                    currency={txn.currency}
                    rechargeAmount={txn.amount}
                    date={txn.date}
                    oldBalance={txnParams?.oldBalance}
                    newBalance={txnParams?.newBalance}
                    promoAmount={txnParams?.promoAmount}
                    cardNumber={paymentCardStore.editableCard?.maskedNumber}
                  />

                  {(store.paymentInfo?.arPromoInfo?.description ||
                    store.paymentInfo?.arPromoBullets) && (
                    <CallingAutoRechargePromoInfo
                      title={store.paymentInfo.arPromoInfo?.description}
                      description={store.paymentInfo.arPromoBullets}
                      className="text-center mb-5"
                    />
                  )}

                  <div className="text-center mt-10">
                    <Button
                      shape="pill"
                      isOutlined
                      size="lg"
                      onClick={handleManageArClick}
                      dataTestId="confirmation"
                    >
                      {t('Manage auto-recharge')}
                    </Button>
                  </div>
                </>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>
    </Spinner>
  );
};

export default observer(CallingRechargeReceipt);
