export const DEFAULT_ERROR_MESSAGE =
  'There was a problem connecting to the service. Please try again.'; // t('There was a problem connecting to the service. Please try again.')

export const NETWORK_ERROR_MESSAGE = 'Please check your connection and try again.'; // t('Please check your connection and try again.')

export const API_ERROR_MSG = {
  NOT_FOUND: 'Resource Not Found', // t('Something went wrong with your email. Please try again.')
  APP_AUTH_EMAIL_LOST: 'Something went wrong with your email. Please try again.', // t('Something went wrong with your email. Please try again.')
  APP_MT_TRANSACTION_FAILED: 'Sorry. The transaction has failed.', // t('Sorry. The transaction has failed.')
  APP_CALLING_TRANSACTION_FAILED: 'Sorry. The transaction has failed.', // t('Sorry. The transaction has failed.')
  APP_IMTU_CALLING_TRANSACTION_FAILED:
    'There’s a problem processing your payment. Please check the payment details or try a different card.', // t('There’s a problem processing your payment. Please check the payment details or try a different card.')
  APP_PROMOCODE_INELIGIBLE: 'Promo code is not eligible', // t('Promo code is not eligible')
  APP_LOCATION_NOT_ALLOWED: 'Money Transfer is not supported in this location', // t('Money Transfer is not supported in this location')
  APP_ACCOUNT_IS_NOT_VERIFIED: DEFAULT_ERROR_MESSAGE,
  APP_DEFAULT: DEFAULT_ERROR_MESSAGE,
  APP_ALREADY_IN_USE: 'Already in use', // t('Already in use)

  FIREBASE_EMAIL_LINK_EXPIRED: 'Email verification link has expired. Please try again.', // t('Email verification link has expired. Please try again.')
  FIREBASE_EMAIL_INVALID:
    'We cannot find your email. Please ensure you are using the same device or browser for the link provided or just enter email again.', // t('We cannot find your email. Please ensure you are using the same device or browser for the link provided or just enter email again.')
  FIREBASE_EMAIL_ALREADY_IN_USE: 'This email is already in use.', // t('This email is already in use.')
  FIREBASE_USER_DISABLED: 'Your account is disabled.', // t('Your account is disabled.')
  FIREBASE_PHONE_CODE_INVALID: 'Please enter a valid verification code from the SMS.', // t('Please enter a valid verification code from the SMS.')
  FIREBASE_UNSUPPORTED_BROWSER:
    'This browser does not support 3rd party cookies or cookies have been disabled.', // t('This browser does not support 3rd party cookies or cookies have been disabled.')
  FIREBASE_DEFAULT: 'Something went wrong. Please try again later.', // t('Something went wrong. Please try again later.')
  APP_FETCH_DELIVERY_INFO_FAILED: 'Failed to retrieve delivery info from server', // t('Failed to retrieve delivery info from server')
  APP_FETCH_FEES_FAILED: 'Failed to load fees for delivery method', // t('Failed to load fees for delivery method')
};

export const GEOLOCATION_ERROR_MSG = {
  PERMISSION_DENIED: 'Please go to your browser settings and enable location services', // t('Please go to your browser settings and enable location services')
  POSITION_UNAVAILABLE: 'Position unavailable', // TODO: clarify text t('Position unavailable')
  TIMEOUT: 'Position unavailable', // TODO: clarify text // t('Position unavailable')
};

export const PAYMENT_CARD_ERROR_MSG = {
  NOT_SUPPORTED_CARD_TYPE:
    'We only accept Visa, Mastercard and American Express at this time', // t('We only accept Visa, Mastercard and American Express at this time')
  INVALID_EXPIRATION_DATE: 'Please enter a valid expiration date', // t('Please enter a valid expiration date')
  UNSUPPORTED_PREPAID_CARD: 'Prepaid cards are not supported', // t('Prepaid cards are not supported')
  ENTER_VALID_NUMBER: 'Please enter a valid card number', // t('Please enter a valid card number')
  CARD_NUMBER_TOO_SHORT: 'Card number too short', // t('Card number too short')
  TOKENIZER_SERVICE_ERROR: DEFAULT_ERROR_MESSAGE,
};

// https://docs.google.com/spreadsheets/d/1QBIdXVG90Gkj8DmQE5fWjbKQhaMUsTbjBhPYbZionMc/edit?ts=5d1b11a9#gid=1734665147
// https://docs.google.com/spreadsheets/d/1yTiy0z9tR2BJ7r28B-iMW3_IWJnNeOJBqWgDj4qktvo/edit#gid=0
export const CALLING_BACK_END_ERRORS = {
  'CARRIER_PROBLEM_CONTACT_CARRIER':
    'The carrier had a problem completing your transaction. Please try again later or contact the carrier.', // t('The carrier had a problem completing your transaction. Please try again later or contact the carrier.')
  'CARRIER_PROBLEM_TRY_AGAIN':
    'The carrier had a problem completing your transaction. Please try again later.', // t('The carrier had a problem completing your transaction. Please try again later.')
  'FAILED_CONTACT_CUSTOMER_SUPPORT':
    "The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.", // t("The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.")
  'FAILED_CARD_RESTRICTED':
    'There was an issue with your card. Please try a different card.', // t('There was an issue with your card. Please try a different card.')
  'FAILED_NO_CREDIT':
    'Insufficient funds. Try a different card or visit a BOSS Revolution retail store to pay in cash.', // t('Insufficient funds. Try a different card or visit a BOSS Revolution retail store to pay in cash.')
  'FAILED_TO_TRANSFER_BALANCE': API_ERROR_MSG.APP_IMTU_CALLING_TRANSACTION_FAILED,
  'FAILED_TRY_AGAIN': API_ERROR_MSG.APP_IMTU_CALLING_TRANSACTION_FAILED, // too generic error which comes from multiple calls
  'INELIGIBLE_PLAN':
    "The recipient's current plan is not eligible for this top-up amount at this time. Please select another amount or contact the carrier.", // t("The recipient's current plan is not eligible for this top-up amount at this time. Please select another amount or contact the carrier.")
  'INVALID_ACCOUNT_TYPE':
    "The recipient's account type is not eligible for this top-up amount. Please select another amount or contact the carrier.", // t("The recipient's account type is not eligible for this top-up amount. Please select another amount or contact the carrier.")
  'INVALID_MSISDN':
    "The mobile number you have provided is invalid. Please verify the recipient's information or contact the carrier.", // t("The mobile number you have provided is invalid. Please verify the recipient's information or contact the carrier.")
  'INVALID_MSISDN_OR_WRONG_CARRIER':
    "The mobile number you have provided is either invalid or is not on the selected carrier's network.", // t("The mobile number you have provided is either invalid or is not on the selected carrier's network.")
  'INVALID_PRODUCT':
    'The product you selected is unavailable at this time. Please select another amount and try again.', // t('The product you selected is unavailable at this time. Please select another amount and try again.')
  'INVALID_RECHARGE_AMOUNT_CONTACT_CARRIER':
    "The recipient's account type is not eligible for this top-up amount. Please select another amount or contact the carrier.", // t("The recipient's account type is not eligible for this top-up amount. Please select another amount or contact the carrier.")
  'MSISDN_NOT_ACTIVE':
    "The mobile number is not active or is suspended. Please verify the recipient's number.", // t("The mobile number is not active or is suspended. Please verify the recipient's number.")
  'MSISDN_NOT_PREPAID':
    "The mobile number is not a prepaid account, therefore a top-up is not allowed. Please verify the recipient's number.", // t("The mobile number is not a prepaid account, therefore a top-up is not allowed. Please verify the recipient's number.")
  'NOT_ALLOWED':
    'Your card issuer has restricted use for this transaction. Try a different card or visit a BOSS Revolution retail store to pay in cash.', // t('Your card issuer has restricted use for this transaction. Try a different card or visit a BOSS Revolution retail store to pay in cash.')
  'TOPUP_TOO_SOON':
    "The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.", // t("The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.")
  'PAYMENT_COMMUNICATION_FAILURE':
    'Oops! Something went wrong. Please try again in a few minutes.', // t('Oops! Something went wrong. Please try again in a few minutes.')
  'PROCESSING_REQUEST':
    'Your request is being processed. Please allow up to 2 hours for the transaction to complete. TO AVOID MULTIPLE CHARGES, PLEASE DO NOT RESUBMIT THIS REQUEST.', // t('Your request is being processed. Please allow up to 2 hours for the transaction to complete. TO AVOID MULTIPLE CHARGES, PLEASE DO NOT RESUBMIT THIS REQUEST.')
  'PRODUCT_UNAVAILABLE': 'The product you selected is unavailable at this time.', // t('The product you selected is unavailable at this time.')
  'UNKNOWN':
    'Sorry, we had a problem processing your credit card. Try a different card or visit a BOSS Revolution retail store to pay in cash.', // t('Sorry, we had a problem processing your credit card. Try a different card or visit a BOSS Revolution retail store to pay in cash.')
  'FAILED': 'Transaction declined. Please use a different card and try again.', // t('Transaction declined. Please use a different card and try again.')
  'FAILED_NO_CARD': API_ERROR_MSG.APP_IMTU_CALLING_TRANSACTION_FAILED,
  'FAILED_CARD_DECLINED':
    'Transaction declined, please use a different card and try again.', // t('Transaction declined, please use a different card and try again.')
  'FAILED_CARD_EXPIRED':
    'The card is expired. Please update your card information or try a different card.', // t('The card is expired. Please update your card information or try a different card.')
  'FAILED_LOST_OR_STOLEN_CC':
    'The card has been declined. Please use a different card and try again.', // t('The card has been declined. Please use a different card and try again.')
  'FAILED_INVALID_CARD_NUMBER':
    'The card number you entered is not valid. Please re-enter the card number or use a different card.', // t('The card number you entered is not valid. Please re-enter the card number or use a different card.')
  'FAILED_INVALID_CARD_INFO':
    'Invalid information. Please re-enter or try a different card.', // t('Invalid information. Please re-enter or try a different card.')
  'VERIFY_WITH_RECIPIENT':
    'There was a problem processing your transaction. Please verify with the recipient.', // t('There was a problem processing your transaction. Please verify with the recipient.')
  '3DS_FAILED': 'Card verification failed.', // t('Card verification failed.')
  'LIMIT_EXCEEDED':
    "The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.", // t("The recipient's account has reached the maximum top-up amount allowed by the carrier. Please try again later.")
};

export const AMOUNT_ERROR_MSG = {
  EXCEEDS_MAX_ALLOWED_AMOUNT_FOR_NIGERIA_NGN: 'Maximum allowed amount is 1 million NGN', // t('Maximum allowed amount is 1 million NGN')
};

export enum MtCcErrorCode {
  RE_SUBMIT_TRANSACTION = 99,
  RE_SUBMIT_TRANSACTION_2 = 102,
  INSUFFICIENT_FUNDS = 110,
  EXCEEDS_APPROVAL_LIMIT = 127,
  PICK_UP_CARD_OTHER_THAN_LOST_STOLEN = 207,
  PICK_UP_CARD_STOLEN = 214,
  RESTRICTED_CARD = 215,
  INCORRECT_CVV = 226,
  SYSTEM_ERROR = 252,
  INVALID_CARD_NUMBER = 301,
  ACCOUNT_NUMBER_DOES_NOT_MATCH_PAYMENT_TYPE = 302,
  PICK_UP_CARD = 303,
  LOST_STOLEN = 304,
  EXPIRED = 305,
  RESTRICTED_CARD_2 = 307,
  RESTRICTED_CARD_FRAUD_AVS_FILTERING_SERVICE = 319,
  INVALID_EXPIRATION_DATE = 320,
  INVALID_MERCHANT = 321,
  INVALID_TRANSACTION = 322,
  NO_SUCH_ISSUER = 323,
  CARD_HOLDER_TRANSACTION_NOT_PERMITTED = 327,
  DO_NOT_HONOR = 349,
  DECLINE = 350,
  CVV_MISMATCH = 352,
  ACCOUNT_WAS_CLOSED = 501,
  INVALID_BANK_ROUTING_NUMBER = 900,
}

export const MT_CC_ERROR_MESSAGES: { [key: string]: string } = {
  [MtCcErrorCode.INSUFFICIENT_FUNDS]:
    'Insufficient funds. Try a different card or contact issuing bank.', // t('Insufficient funds. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.EXCEEDS_APPROVAL_LIMIT]:
    'Insufficient funds. Try a different card or contact issuing bank.', // t('Insufficient funds. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.INCORRECT_CVV]:
    'Information you entered is not valid. Please reenter or use a different card.', // t('Information you entered is not valid. Please reenter or use a different card.'),
  [MtCcErrorCode.INVALID_CARD_NUMBER]:
    'Information you entered is not valid. Please reenter or use a different card.', // t('Information you entered is not valid. Please reenter or use a different card.'),
  [MtCcErrorCode.ACCOUNT_NUMBER_DOES_NOT_MATCH_PAYMENT_TYPE]:
    'Information you entered is not valid. Please reenter or use a different card.', // t('Information you entered is not valid. Please reenter or use a different card.'),
  [MtCcErrorCode.PICK_UP_CARD]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.LOST_STOLEN]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.EXPIRED]:
    'Card expired. Please update your card information and try again.', // t('Card expired. Please update your card information and try again.'),
  [MtCcErrorCode.RESTRICTED_CARD]:
    'This transaction type is not allowed by the card issuer. Please try a different card.', // t('This transaction type is not allowed by the card issuer. Please try a different card.'),
  [MtCcErrorCode.RESTRICTED_CARD_2]:
    'This transaction type is not allowed by the card issuer. Please try a different card.', // t('This transaction type is not allowed by the card issuer. Please try a different card.'),
  [MtCcErrorCode.INVALID_EXPIRATION_DATE]:
    'Card expired. Please update your card information and try again.', // t('Card expired. Please update your card information and try again.'),
  [MtCcErrorCode.INVALID_MERCHANT]:
    'This transaction type is not allowed by the card issuer. Please try a different card.', // t('This transaction type is not allowed by the card issuer. Please try a different card.'),
  [MtCcErrorCode.INVALID_TRANSACTION]:
    'This transaction type is not allowed by the card issuer. Please try a different card.', // t('This transaction type is not allowed by the card issuer. Please try a different card.'),
  [MtCcErrorCode.CARD_HOLDER_TRANSACTION_NOT_PERMITTED]:
    'This transaction type is not allowed by the card issuer. Please try a different card.', // t('This transaction type is not allowed by the card issuer. Please try a different card.'),
  [MtCcErrorCode.DO_NOT_HONOR]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.DECLINE]: 'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.CVV_MISMATCH]:
    'Information you entered is not valid. Please reenter or use a different card.', // t('Information you entered is not valid. Please reenter or use a different card.'),
  [MtCcErrorCode.PICK_UP_CARD_STOLEN]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.PICK_UP_CARD_OTHER_THAN_LOST_STOLEN]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.RE_SUBMIT_TRANSACTION]:
    'We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.', // t('We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.'),
  [MtCcErrorCode.RE_SUBMIT_TRANSACTION_2]:
    'We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.', // t('We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.'),
  [MtCcErrorCode.RESTRICTED_CARD_FRAUD_AVS_FILTERING_SERVICE]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.NO_SUCH_ISSUER]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.ACCOUNT_WAS_CLOSED]:
    'Bank decline. Try a different card or contact issuing bank.', // t('Bank decline. Try a different card or contact issuing bank.'),
  [MtCcErrorCode.SYSTEM_ERROR]:
    'We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.', // t('We are unable to process your transaction at this time. Please wait 30 minutes and resubmit the transaction.'),
  [MtCcErrorCode.INVALID_BANK_ROUTING_NUMBER]:
    'Information you entered is not valid. Please reenter or use a different card.', // t('Information you entered is not valid. Please reenter or use a different card.'),
};
