import React from 'react';
import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import CallingPlan from '@root/models/CallingPlans/CallingPlan';

import BrCallingPlanCard from '../../../../components/BrCallingPlanCard';

interface Props {
  title: string;
  data: CallingPlan[];
  selectedPlanId?: string;
  className?: string;
  onPlanSelect?(planId: string): void;
}

const PlansList: React.FunctionComponent<Props> = (props: Props) => {
  const { title, data, className, onPlanSelect } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound([className, 'space-y-xsmall'])}>
      <div className="text-headers/header-6 text-on-colors/on-background py-small">
        {title}
      </div>
      <div className="flex flex-col space-y-middle">
        {data.map((plan) => {
          const planSubTypeText = plan.isSubscriptionType
            ? t('Subscription')
            : t('One time plan');
          const imtuProduct = plan.comboProducts?.find((comboProduct) => {
            return comboProduct.type === 'imtu';
          });
          /* eslint-disable */
          const bundledProduct = imtuProduct
            ? {
                title: imtuProduct.title,
                descriptionShort: plan.comboPromoTagline,
                descriptionLong: imtuProduct.description,
              }
            : undefined;
          /* eslint-enable */

          let actionText: string;
          switch (true) {
            case plan.isManageable:
              actionText = t('Manage');
              break;
            case plan.isFree:
              actionText = t('Free');
              break;
            default:
              actionText = plan.basePriceText;
          }

          return (
            <BrCallingPlanCard
              key={plan.planId}
              id={plan.planId}
              planTypeText={planSubTypeText}
              title={plan.defaultTitle}
              descriptionShort={plan.descriptionShort}
              actionText={actionText}
              actionType={plan.isManageable ? 'manage' : 'purchase'}
              descriptionFull={plan.descriptionFull}
              destinations={plan.destinations}
              footnotes={plan.destinationFootnotes}
              bundledProduct={bundledProduct}
              isCombo={plan.isCombo}
              isNonPurchasable={plan.isNoNPurchasable}
              onActionBtnClick={onPlanSelect}
              unavailableContent={
                plan.isNoNPurchasable ? t('The product is not available') : undefined
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default PlansList;
