import React, {
  ForwardedRef,
  ReactNode,
  TransitionEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { normalizeStringCompound } from '@utils/string';

import useScrollBlock from '@root/hooks/useScrollBlock';

import BrScrollShadowWrapper from '../BrScrollShadowWrapper';
import Overlay from './Overlay';
import ContentWrapper from './ContentWrapper';
import DraggableEar from './DraggableEar';
import Header from './Header';
import useVirtualKeyboardHeight from './useVirtualKeyboardHeight';

interface BrDrawerProps {
  title?: string;
  className?: string;
  contentWrapperClassName?: string;
  id?: string;
  isOpen?: boolean;
  onClose?(): void;
  onAnimationEnd?(): void;
  onBackBtnClick?(): void;
  children?: ReactNode;
  addonTop?: React.ReactNode;
  addonBottom?: React.ReactNode;
}

export function animationComplete(element: HTMLElement) {
  return Promise.allSettled(
    element.getAnimations().map((animation) => animation.finished),
  );
}

const Z_LAYER = 'z-110';
const DRAWER_MIN_HEIGHT_MARGIN = 150;

const BrDrawer = forwardRef(
  (
    props: BrDrawerProps,
    ref: ForwardedRef<{ drawerRef: React.RefObject<HTMLDivElement> }>,
  ) => {
    const {
      children,
      isOpen,
      id,
      className,
      contentWrapperClassName,
      onClose,
      title,
      onBackBtnClick,
      onAnimationEnd,
      addonTop,
      addonBottom,
    } = props;

    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerContentRef = useRef<HTMLDivElement>(null);
    const scrollShadowWrapperRef = useRef<{
      scrollableNodeRef: React.RefObject<HTMLDivElement>;
    }>(null);

    const [isOpened, setIsOpened] = useState(isOpen);
    const [isPrevOpened, setIsPrevOpened] = useState<boolean | undefined>(false);

    const { allowScroll, blockScroll } = useScrollBlock();

    if (isPrevOpened !== isOpen) {
      setIsPrevOpened(isOpened);
      setIsOpened(Boolean(isOpen));
    }

    useEffect(() => {
      if (isOpen) {
        blockScroll();
      } else {
        allowScroll();
      }
    }, [isOpen]);

    const handleOnClose = async () => {
      setIsOpened(false);
      if (drawerRef.current) {
        await animationComplete(drawerRef.current);
      }
      onClose?.();
    };

    const handleClickOutside = () => {
      setIsOpened(false);
      onAnimationEnd?.();
    };

    useOnClickOutside(handleClickOutside, drawerRef);

    const handleOnTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        if (scrollShadowWrapperRef.current?.scrollableNodeRef.current) {
          scrollShadowWrapperRef.current.scrollableNodeRef.current.scrollTop = 0;
        }
        onAnimationEnd?.();
      }
    };

    useImperativeHandle(ref, () => {
      return { drawerRef };
    });

    const virtualKeyboardHeight = useVirtualKeyboardHeight();

    const getDrawerContainerMinHeightStyles = () => {
      if (virtualKeyboardHeight) {
        return {
          minHeight: `min(${virtualKeyboardHeight + DRAWER_MIN_HEIGHT_MARGIN}px,80dvh)`,
        };
      }
      return undefined;
    };

    const drawerClassNames = normalizeStringCompound([
      className,
      isOpen ? 'drawer-open' : '',
      'drawer pointer-events-none fixed bottom-0 left-0 h-full w-full transition-opacity duration-300 [&.drawer-open]:pointer-events-auto [&.drawer-open_.br-drawer-content-wrapper]:translate-y-0 [&.drawer-open_.drawer-overlay]:opacity-100',
    ]);

    const drawerContentWrapperClassNames = normalizeStringCompound([
      'br-drawer-content-wrapper !absolute bottom-0 flex h-auto w-full translate-y-full transform flex-col duration-300',
      contentWrapperClassName,
      Z_LAYER,
    ]);

    const drawerContentClassNames =
      'br-drawer-content relative [max-height:min(650px,80vh)] landscape:[max-height:min(650px,70vh)] flex flex-col flex-shrink-0 flex-grow !overflow-hidden rounded-t-default bg-white p-xlarge space-y-middle';

    return (
      <div data-id={id} className={drawerClassNames}>
        <Overlay />
        <ContentWrapper
          ref={drawerRef}
          onTransitionEnd={handleOnTransitionEnd}
          className={drawerContentWrapperClassNames}
        >
          <DraggableEar />
          <div
            style={getDrawerContainerMinHeightStyles()}
            className={drawerContentClassNames}
            ref={drawerContentRef}
          >
            <Header
              onBackBtnClick={onBackBtnClick}
              title={title}
              onClose={handleOnClose}
            />
            {addonTop}
            <BrScrollShadowWrapper ref={scrollShadowWrapperRef}>
              {children}
            </BrScrollShadowWrapper>
            {addonBottom}
          </div>
        </ContentWrapper>
      </div>
    );
  },
);

export default BrDrawer;
