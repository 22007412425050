import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { getAvailableLanguageOptions } from '@helpers/language';
import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

import BrMenu from '@components/common/BrMenu';
import { MenuItemProps } from '@components/common/BrMenu/MenuItem';

import ROUTE_PATHS from '@root/routes/paths';

import i18n from '@services/i18n';
import { trackLogout } from '@services/analytics';
import { liveChatStart } from '@services/liveChat';

import { LoginStore, AppConfigStore, UserAccountStore } from '@root/stores';

import useStore from '@root/hooks/useStore';

import { IconNames } from '@root/interfaces/components/Icon';
import CountryCode from '@root/interfaces/CountryCode';
import { Languages } from '@root/interfaces/Languages';
import { SUPPORT_URL_TEMPLATES } from '@root/constants/storeUrls';

const LocaleToCountryCodeMap: Record<Languages, CountryCode> = {
  en: 'US',
  es: 'ES',
  fr: 'FR',
};

const BrMobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const loginStore: LoginStore = useStore('LoginStore');

  const languageOptions = getAvailableLanguageOptions(
    userAccountStore.userCountryOfOrigin,
  );

  const fallbackLocale = getFallbackLocaleForLegalUrls(
    userAccountStore.currentLanguageLocale,
  );

  const handleChatLinkClick = () => {
    liveChatStart();
  };

  const handleLogoutClick = async () => {
    await loginStore.sessionLogOut();
    trackLogout(userAccountStore.financeProfile?.brCustomerId || ''); // TODO: replace bmbCustomerId to profile.id after completely switch to DTC backend
    history.replace(ROUTE_PATHS.LOGIN);
  };

  const handleLanguageSelect = (id: Languages) => {
    userAccountStore.changeLanguage(id);
  };

  const languageMenuItems = languageOptions.reduce<MenuItemProps[]>((acc, langOpt) => {
    const option = {
      id: langOpt.value,
      title: langOpt.nativeName,
      description:
        i18n.language === langOpt.value ? t('Preferred language') : t(langOpt.text),
      onClick: () => handleLanguageSelect(langOpt.value),
      countryCode: LocaleToCountryCodeMap[langOpt.value],
      isActive: i18n.language === langOpt.value,
    };

    // active item is always at the top of the list
    if (option.id === i18n.language) {
      return [option, ...acc];
    }
    return [...acc, option];
  }, []);

  const languageMenuOptionTitle = useMemo(() => {
    return (
      languageMenuItems.find((item) => {
        return item.id === i18n.language;
      })?.title || t('Select language')
    );
  }, [languageMenuItems, t]);

  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const settingsAndSupport: MenuItemProps[] = [
    {
      id: 'Auto-recharge settings',
      title: t('Auto-recharge settings'),
      iconName: 'AutoRechargeIcon',
      menuUrl: ROUTE_PATHS.CALLING,
    },
    {
      id: 'chat',
      title: t('Chat'),
      iconName: 'chatbubble-outline',
      onClick: handleChatLinkClick,
    },
    {
      id: 'help',
      title: t('Help'),
      iconName: 'help-circle-outline',
      menuUrl: SUPPORT_URL_TEMPLATES.FAQ.replace('{{langLocale}}', fallbackLocale),
      target: '_blank',
    },
  ];
  const data: MenuItemProps[] = [
    {
      id: 'Home',
      title: t('Home'),
      iconName: 'home-outline',
      menuUrl: ROUTE_PATHS.ACCOUNT,
    },
    {
      id: 'Mobile Top-Up',
      title: t('Mobile Top-Up'),
      iconName: 'top-up-outline',
      menuUrl: ROUTE_PATHS.IMTU,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isImtuAvailable,
    },
    {
      id: 'Calling Plans',
      title: t('Calling Plans'),
      iconName: 'plans-outline',
      menuUrl: ROUTE_PATHS.CALLING_PLANS,
      isNew: true,
      highlightedUrls: [
        ROUTE_PATHS.CALLING_PLANS,
        ROUTE_PATHS.CALLING_PLAN_RECEIPT,
        ROUTE_PATHS.CALLING_PLAN_COMPLETED_ORDER,
        ROUTE_PATHS.CALLING_AUTO_RECHARGE,
        ROUTE_PATHS.CALLING_RECHARGE_RECEIPT,
      ],
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isCallingPlansAvailable,
    },
    {
      id: 'Calling Recharge',
      title: t('Calling Recharge'),
      iconName: 'recharge',
      menuUrl: ROUTE_PATHS.CALLING,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isCallingAvailable,
    },
    {
      id: 'Money Transfer',
      title: t('Money Transfer'),
      iconName: 'money-outline',
      menuUrl: ROUTE_PATHS.MT_MODAL,
      isDisabled: !appConfigStore.availableMainFeaturesCfg.isMtAvailable,
    },
    {
      id: 'Transaction history',
      title: t('Transaction history'),
      iconName: 'time-outline',
      menuUrl: ROUTE_PATHS.TRANSACTIONS_HISTORY,
    },
    {
      id: 'My profile',
      title: t('My profile'),
      iconName: 'person-outline',
      menuUrl: ROUTE_PATHS.ACCOUNT_PROFILE,
    },
    {
      id: 'Settings and Support',
      title: t('Settings and Support'),
      iconName: 'settings-outline',
      items: settingsAndSupport,
    },
    {
      id: 'Languages',
      title: languageMenuOptionTitle,
      description: t('Language'),
      iconName: 'globe-outline',
      items: languageMenuItems,
    },
    {
      id: 'logout',
      title: t('Log out'),
      iconName: 'log-out-outline',
      onClick: handleLogoutClick,
    },
  ]
    .filter((item) => {
      return !item.isDisabled;
    })
    .map((item) => {
      const pathFirstLevel = `/${window.location.pathname.split('/')[1]}`;

      return {
        ...item,
        iconName: item.iconName as IconNames,
        isActive:
          window.location.pathname === item.menuUrl ||
          item.highlightedUrls?.includes(pathFirstLevel),
      };
    });

  return <BrMenu data={data} />;
};

export default observer(BrMobileMenu);
