import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSortByKeyFn } from '@utils/array';

import BrSelectProps from '@root/interfaces/components/BrSelect';
import CountryCode from '@root/interfaces/CountryCode';
import { OptionProps } from '@root/interfaces/components/Select';

import BrSelect from '../BrSelect';
import Flag from '../Flag';
import Icon from '../Icon';

export type CountryItem = {
  code: CountryCode;
  value: string;
  textLabel: string;
};

interface Props extends Omit<BrSelectProps, 'options' | 'disabled' | 'setForcedOpen'> {
  data: CountryItem[];
  onChange?(value: string): void;
}

interface Props extends Omit<BrSelectProps, 'options'> {
  value: string;
}

const BrCountrySelect: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    data,
    value,
    placeholder,
    isOpened,
    isDisabled,
    onChange,
    drawerTitleText,
    ...rest
  } = props;

  const [countryCode, setCountryCode] = useState(value);

  const selectButtonAddonBefore = countryCode && (
    <Flag
      className="w-[16px] h-[16px] overflow-hidden rounded-full flex-shrink-0 mx-middle self-center"
      code={countryCode as CountryCode}
    />
  );

  const { t } = useTranslation();

  const countryOptions: OptionProps[] = data
    .sort(getSortByKeyFn('textLabel'))
    .map((item) => {
      return {
        value: item.value,
        text: item.textLabel,
        label: (
          <div className="flex items-center w-full">
            <Flag
              className="mr-small h-[20px] w-[20px] overflow-hidden rounded-full"
              code={item.code}
            />
            <span className="leading-none">{t(item.textLabel)}</span>
            {countryCode === item.code ? (
              <div className="ml-auto w-[17px] h-[17px] rounded-full bg-transparent flex items-center justify-center p-[3px]">
                <Icon
                  name="checkmark-circle"
                  className="bg-transparent text-on-colors/on-secondary-variant"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ),
      };
    });

  const handleOnChange = (newValue: string) => {
    setCountryCode(newValue);
    onChange?.(newValue);
  };

  return (
    <div>
      <BrSelect
        selectButtonAddonBefore={selectButtonAddonBefore}
        options={countryOptions}
        onChange={handleOnChange}
        value={countryCode}
        placeholder={placeholder}
        isOpened={isOpened}
        isDisabled={isDisabled}
        drawerTitleText={drawerTitleText}
        {...rest}
      />
    </div>
  );
};

export default BrCountrySelect;
