import { Offer, OffersEndpointRequest } from '@root/interfaces/contract/dtcImtuK2/Offers';
import { TransactionsEndpointRequest } from '@root/interfaces/contract/dtcImtuK2/Transactions';
import { PromoInfoEndpointRequest } from '@root/interfaces/contract/dtcImtuK2/PromoInfo';
import { PurchaseEndpointRequest } from '@root/interfaces/contract/dtcImtuK2/Purchase';

import { formatPhoneToE164 } from '@helpers/phone';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import { ImtuProductClassParams } from '@root/models/ImtuProductClass';

import BASE_PATHS from '@root/routes/paths';

import { IMTU_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

export const getCarriersReqParams = (recipientPhone: string): OffersEndpointRequest => {
  return {
    recipient_msisdn: formatPhoneToE164(recipientPhone),
    orig_recipient_number: formatPhoneToE164(recipientPhone),
  };
};

export interface ImtuOffersServiceData {
  recipientPhone: string;
  carrierCode: string;
}

export const getOffersReqParams = ({
  recipientPhone,
  carrierCode,
}: ImtuOffersServiceData): OffersEndpointRequest => {
  return {
    recipient_msisdn: formatPhoneToE164(recipientPhone),
    orig_recipient_number: formatPhoneToE164(recipientPhone),
    carrier_code: carrierCode,
  };
};

export const getTxnDetailsReqParams = (txnId: string): TransactionsEndpointRequest => {
  return { txid: txnId };
};

export const getAllTxnsReqParams = (): TransactionsEndpointRequest => {
  return { all_transactions: true };
};

export interface FetchPromoInfoServiceReqData {
  offerId: string;
  promoId: string;
  recipientPhone: string;
}

export const getPromoInfoReqParams = (
  params: FetchPromoInfoServiceReqData,
): PromoInfoEndpointRequest => {
  return {
    OfferID: params.offerId,
    promo_id: params.promoId,
    recipient_msisdn: params.recipientPhone,
  };
};

export interface SendImtuTxnServiceData {
  recipientPhone: string;
  offerId: string;
  cardHandleId: string;
  cvv: string;
  promoId?: string;
}

export const getTransactionsReqParams = (
  params: SendImtuTxnServiceData,
): PurchaseEndpointRequest => {
  const resultObject: PurchaseEndpointRequest = {
    imtu_request: {
      installation_id: navigator.userAgent || navigator.vendor,
      offer_id: params.offerId,
      orig_recipient_number: formatPhoneToE164(params.recipientPhone),
      recipient_msisdn: formatPhoneToE164(params.recipientPhone),
      handle_id: params.cardHandleId,
      cvv: Number(params.cvv),
      return_url: `${window.location.origin}${BASE_PATHS.IMTU_RECEIPT}/${IMTU_3DS_PENDING_TXN_ID_STORE_KEY}`,
    },
  };
  if (params.promoId) {
    resultObject.promo_id = params.promoId;
  }
  return resultObject;
};

export const convertOfferToImtuProductClassParams = (
  data: Offer,
): ImtuProductClassParams => {
  const res: ImtuProductClassParams = {
    code: data.offer_id,
    countryCode: data.destination_country,
    name: data.note?.denom_text || data.product_name,
    carrier: new ImtuCarrierClass(data.carrier),
    senderCurrency: data.price.currency,
    senderAmount: Number(data.price.base_price),
    senderTotalAmount: Number(data.price.price),
    fee: Number(data.price.idt_service_fee) || 0,
  };
  if (data.price?.value?.value) {
    res.recipientAmount = Number(data.price.value.value);
    res.recipientCurrency = data.price.value.currency;
  }

  if (data.product_description) {
    res.description = data.product_description;
  }

  return res;
};
