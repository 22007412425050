export type CallingPlanTxnStatus = 'success' | 'queued' | 'failed';

export type CallingPlanOrderStatus = 'success' | 'failed' | 'queued' | 'receipt';

// eslint-disable-next-line import/prefer-default-export
export const CALLING_PLANS_STATUSES: {
  [key in 'SUCCESS' | 'QUEUED' | 'FAILED']: CallingPlanTxnStatus;
} = {
  SUCCESS: 'success',
  QUEUED: 'queued',
  FAILED: 'failed',
};
