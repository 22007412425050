import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import ContactsStore from '@components/modules/BrFavoriteNumbers/ContactsStore';

import CountryCode from '@root/interfaces/CountryCode';

import useDidMount from '@root/hooks/useDidMount';

import { showBrToast, showToast } from '@services/toasts';

import { Spinner } from '@components/common';
import BrCallingPlansFavoriteNumberCard from '@components/common/BrCallingPlansFavoriteNumberCard';

interface Props {
  planId: string;
  maxFavoriteNumbers: number;
  countryCode: CountryCode;
}

const BrFavoriteNumbers: React.FunctionComponent<Props> = (props: Props) => {
  const { planId, maxFavoriteNumbers, countryCode } = props;

  const { t } = useTranslation();

  const contactsStore = useLocalStore(() => new ContactsStore(planId));

  useDidMount(() => {
    if (maxFavoriteNumbers) {
      contactsStore.getContacts();
    }
  });

  const handleContactAddClick = async (phoneNumber: string) => {
    try {
      await contactsStore.addContact(phoneNumber);
      await contactsStore.getContacts();
      showBrToast.small(`${t('The phone number has been saved')} ✅`);
    } catch (err) {
      showToast.error(err);
      throw err;
    }
  };

  const handleContactDeleteClick = async (phoneNumber: string) => {
    try {
      await contactsStore.deleteContact(phoneNumber);
      await contactsStore.getContacts();
      showBrToast.small(`${t('The phone number was deleted')} ✅`);
    } catch (err) {
      showToast.error(err);
    }
  };

  const handleContactEditClick = async ({
    oldPhoneNumber,
    newPhoneNumber,
  }: {
    oldPhoneNumber: string;
    newPhoneNumber: string;
  }) => {
    try {
      await contactsStore.deleteContact(oldPhoneNumber);
      await contactsStore.addContact(newPhoneNumber);
      await contactsStore.getContacts();
      showBrToast.small(`${t('The phone number was updated')} ✅`);
    } catch (err) {
      // refresh contacts in case unexpected error occurs on add call
      await contactsStore.getContacts();
      showToast.error(err);
      throw err;
    }
  };

  return (
    <Spinner isSpinning={contactsStore.isLoading} hasNoBg size="sm">
      <BrCallingPlansFavoriteNumberCard
        countryCode={countryCode}
        maxPhoneNumbers={maxFavoriteNumbers}
        phoneNumbers={contactsStore.phoneNumbers}
        onPhoneSubmit={handleContactAddClick}
        onPhoneDelete={handleContactDeleteClick}
        onPhoneEdit={handleContactEditClick}
        isLoading={contactsStore.isLoading}
      />
    </Spinner>
  );
};

export default observer(BrFavoriteNumbers);
