/* eslint-disable i18next/no-literal-string */
import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// TODO: put types in its own folder
import { SelectForwardRef, SelectRef } from '@components/common/Select';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

import { normalizeStringCompound } from '@utils/string';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { OptionProps } from '@root/interfaces/components/Select';

import BrOption from './BrOption';
import BrSelect from '../BrSelect';
import BrSelectInput from './BrSelectInput';
import BrButton from '../BrButton';
import BrPaymentCardReducedDetailsForm from '../BrPaymentCardReducedDetailsForm';

import useBrPaymentCardSelect from './useBrPaymentCardSelect';

import { getFullCardTypeName } from './helpers';
import mapPaymentCardsToSortedBrSelectOptions from './helpers/mapPaymentCardsToSortedBrSelectOptions';

export type BrSelectData = { data: PaymentCardClass; isSupported?: boolean }[];

interface Props {
  value?: string;
  minDropdownBottomOffset?: number;
  isOpenForced?: boolean;
  isInert?: boolean;
  isLoading?: boolean;
  isUsingCardsWithoutBillingAllowed?: boolean;
  data?: BrSelectData;
  paymentTypeFeeMap?: Dictionary<number>;
  selectRef?: SelectForwardRef;
  clickOutsideIgnoreRefs?: React.RefObject<HTMLDivElement>[];
  onCardAdd?(): void;
  onCardEdit?(value?: string): void;
  onChange?(cardId: string): void;
  onEdit?(cardId: string): void;
}

export interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<SelectRef>> {
  useBrPaymentCardSelect: typeof useBrPaymentCardSelect;
  BrPaymentCardReducedDetailsForm: typeof BrPaymentCardReducedDetailsForm;
}

type RenderListItemParams = {
  option: OptionProps;
  onSelect: (options: OptionProps) => void;
  isActive: boolean;
};

const SELECT_DROPDOWN_MAX_HEIGHT = 300;
const MAX_CARDS_AMOUNT = 5;

const BrPaymentCardSelect = forwardRef((props: Props, ref: SelectForwardRef) => {
  const { data, value, onChange, onCardEdit, onCardAdd } = props;

  const { t } = useTranslation();
  const isDesktopResolution = useIsDesktop();

  const selectRef = useRef<SelectRef | null>(null);
  const customSelectBtnRef = useRef<HTMLDivElement | null>(null);

  const activePaymentCard = data?.find((c) => value === c.data.handleId);

  const activeCardType = getFullCardTypeName(activePaymentCard?.data.type);

  const handleChange = (newValue: string) => {
    onChange?.(newValue);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAnimationEnded, setAnimationEnded] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    setAnimationEnded(false);
  };
  const handleClose = () => {
    setIsOpen(false);
    setAnimationEnded(false);
  };

  const handleAnimationEnd = () => {
    setAnimationEnded(true);
  };

  const cardOptions = data ? mapPaymentCardsToSortedBrSelectOptions(data, value) : [];

  const renderSelectButton = (isCmpOpen: boolean, onClick: () => void) => {
    if (activePaymentCard) {
      return (
        <div ref={customSelectBtnRef}>
          <BrSelectInput
            isExpired={activePaymentCard.data.isExpired}
            cardType={activeCardType}
            maskedNumber={`**** ${activePaymentCard.data.maskedNumber
              .slice(-4)
              .toUpperCase()}`}
            paySource={activePaymentCard.data.paySource!}
            onEditClick={onClick}
          />
        </div>
      );
    }
    return undefined;
  };

  const renderListItem = (params: RenderListItemParams, itemIdx: number) => {
    const { option, onSelect, isActive } = params;

    const pCard = data?.find((item) => item.data.handleId === option.value);

    const handleOnSelect = () => {
      // 🚨 NOTE: according to the design spec expired cards are only editable (user can only click edit icon on the right side of the list card item)
      if (!pCard?.data.isExpired) {
        onSelect(option);
      }
    };

    const isLastListItem = cardOptions.length - 1 === itemIdx;

    const handleOnEditClick = () => {
      if (pCard) {
        onCardEdit?.(pCard.data.handleId);
      }
    };

    return (
      <button
        className={normalizeStringCompound([
          'w-full',
          'md:px-default',
          pCard?.data.isExpired ? 'cursor-default' : '',
        ])}
        onClick={handleOnSelect}
      >
        {pCard && (
          <BrOption
            className={normalizeStringCompound([
              isLastListItem ? '' : 'border-b border-support-colors/lines',
            ])}
            paySource={pCard.data.paySource!}
            maskedNumber={`**** ${pCard.data.maskedNumber.slice(-4).toUpperCase()}`}
            isSelected={isActive}
            cardType={getFullCardTypeName(pCard.data.type)}
            onEditClick={handleOnEditClick}
            isExpired={pCard.data.isExpired}
          />
        )}
      </button>
    );
  };

  const addonBottomComp = (
    <BrTooltipWrapper
      cfg={[
        {
          place: 'top',
          content: `${t('You can only add up to {{maxCardsNumber}} payment cards', {
            maxCardsNumber: MAX_CARDS_AMOUNT,
          })}. ${t('Please remove one to add a new card')}.`,
        },
        /* eslint-disable @typescript-eslint/indent */
        isOpen && isAnimationEnded && activePaymentCard?.data.isExpired
          ? {
              color: 'Attention',
              content: t('Add or update your payment card to complete the order'),
              place: isDesktopResolution ? 'bottom' : 'top',
              defaultIsOpen: true,
            }
          : undefined,
        /* eslint-enable @typescript-eslint/indent */
      ]}
    >
      <BrButton
        iconName="add"
        hasIconLeft
        text={t('Add payment card')}
        className="w-full"
        cmpType="gray"
        onClick={onCardAdd}
        disabled={cardOptions.length >= MAX_CARDS_AMOUNT}
      />
    </BrTooltipWrapper>
  );

  const dropdownAddonBottom = addonBottomComp;
  const drawerAddonBottom = addonBottomComp;

  return (
    <BrSelect
      onChange={handleChange}
      options={cardOptions}
      value={value}
      ref={selectRef}
      renderItem={renderListItem}
      ignoredElemsRefs={[customSelectBtnRef]}
      drawerTitleText={t('Select payment card')}
      dropdownAddonBottom={dropdownAddonBottom}
      drawerAddonBottom={drawerAddonBottom}
      dropdownMaxHeight={SELECT_DROPDOWN_MAX_HEIGHT}
      onOpen={handleOpen}
      onClose={handleClose}
      onAnimationEnd={handleAnimationEnd}
      isAllowUnselect={false}
    >
      {renderSelectButton}
    </BrSelect>
  );
}) as CompoundedComponent;

BrPaymentCardSelect.useBrPaymentCardSelect = useBrPaymentCardSelect;
BrPaymentCardSelect.BrPaymentCardReducedDetailsForm = BrPaymentCardReducedDetailsForm;

export default BrPaymentCardSelect;
