import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormConfig } from '@root/interfaces/components/Form';
import CountryCode from '@root/interfaces/CountryCode';

import { Form } from '@components/common';

import {
  getPhoneDialCodeByCountryCode,
  getPhoneInfoByNumber,
  getPhoneMaskByCountryCode,
  isValidInternationalPhoneNumber,
} from '@helpers/phone';

import Yup from '@utils/validation/yup';

interface Props {
  phone?: string;
  countryCode: CountryCode;
  onSubmit?(value: string): void;
  onSubmitValidationError(): void;
}

const PhoneForm = forwardRef((props: Props, ref) => {
  const { phone, countryCode, onSubmit, onSubmitValidationError } = props;

  const { t } = useTranslation();

  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => {
    return {
      submit: () => {
        hiddenSubmitButtonRef.current?.click();
      },
    };
  });

  const handleSubmitValidationError = () => {
    onSubmitValidationError?.();
  };

  const formInitialProps: FormConfig<{ phone: string }> = useMemo(
    () => ({
      enableReinitialize: true,
      initialValues: {
        phone: phone || `+${getPhoneDialCodeByCountryCode(countryCode)}`,
      },
      validationSchema: Yup.object().shape({
        phone: Yup.string()
          .ensure()
          .label('phone')
          .test(
            'is valid phone number',
            t('The number that you entered does not look like a valid phone number'),
            (value: string) => {
              if (value) {
                return isValidInternationalPhoneNumber(value);
              }
              return false;
            },
          )
          .test(
            'does phone belongs to country',
            t('Wrong country number'),
            (value: string) => {
              return countryCode === getPhoneInfoByNumber(value).country;
            },
          ),
      }),
      onSubmit: (values: { phone: string }) => {
        onSubmit?.(values.phone);
      },
      onSubmitValidationError: handleSubmitValidationError,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone],
  );

  return (
    <Form config={formInitialProps} className="w-full max-w-xs">
      <Form.Field
        type="maskedText"
        mask={`+${getPhoneMaskByCountryCode(countryCode)}`}
        name="phone"
        required
      />
      {/* Hidden submit button - submit triggers from the outside of the form */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button ref={hiddenSubmitButtonRef} type="submit" className="hidden" />
    </Form>
  );
});

export default PhoneForm;
