import React, { FC } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import BASE_PATHS from '@root/routes/paths';

import useStore from '@root/hooks/useStore';
import useWizard from '@root/hooks/useWizard';
import useDidMount from '@root/hooks/useDidMount';

import { UserAccountStore } from '@root/stores';
import CallingRechargeStore from '@root/stores/CallingRechargeStore';

import {
  Spinner,
  PageTitle,
  AdaptiveCancelButton,
  Modal,
  Logo,
  AdaptiveHelpButton,
} from '@components/common';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import storageInterface from '@services/storage';
import { CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

import { Steps } from './constants';

import Recharge, { RechargeFinishParams } from './containers/Recharge';

interface Props {
  basePath: string;
}

export interface StepModule {
  component: React.ReactNode;
}

const DATA_TEST_ID_PREFIX = 'calling';

const Calling: FC<React.PropsWithChildren<Props>> = (props) => {
  const { basePath } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const callingRechargeStore = useLocalStore(() => new CallingRechargeStore());

  const { stepPaths, availableSteps, currentStepIndex } = useWizard({
    basePath,
    steps: Steps,
  });

  useDidMount(async () => {
    await callingRechargeStore.getCallingInfo();
    const qsParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const tnxId3Ds = storageInterface.getItem(
      CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY,
    );
    storageInterface.removeItem(CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY);
    const query3DsParams =
      'resubmit3DsParams' in qsParams
        ? JSON.parse(String(qsParams.resubmit3DsParams))
        : undefined;
    if (
      query3DsParams &&
      'amount' in query3DsParams &&
      'isAutoRechargeEnabled' in query3DsParams &&
      'paymentCardId' in query3DsParams
    ) {
      await callingRechargeStore.submitTransaction({
        amount: Number(query3DsParams.amount),
        isAutoRechargeEnabled: Boolean(query3DsParams.isAutoRechargeEnabled),
        paymentCardId: String(query3DsParams.paymentCardId),
        userCountryCode: userAccountStore.userCountryOfOrigin,
        tnxId3Ds: tnxId3Ds ?? undefined,
        validatedPromoCode:
          'validatedPromoCode' in query3DsParams
            ? String(query3DsParams.validatedPromoCode)
            : undefined,
      });
      if (callingRechargeStore.rechargeReceipt) {
        const txnPrams = qs.stringify({
          txnParams: JSON.stringify({
            oldBalance: callingRechargeStore.rechargeReceipt.oldBalance,
            newBalance: callingRechargeStore.rechargeReceipt.newBalance,
            promoAmount: callingRechargeStore.rechargeReceipt.promoAmount,
            cardHandleId: query3DsParams.paymentCardId,
          }),
        });
        history.push(
          `${BASE_PATHS.CALLING_RECHARGE_RECEIPT}/${callingRechargeStore.rechargeReceipt.id}?${txnPrams}`,
        );
      }
    }
  });

  const handleCancelButtonClick = () => {
    // TODO: we need better design for this calling button since it not always a CANCEL button
    if (currentStepIndex) {
      history.push(basePath);
    } else {
      history.push(BASE_PATHS.ACCOUNT);
    }
  };

  const handleRechargeStepFinish = async (params: RechargeFinishParams) => {
    const { amount, isAutoRechargeEnabled, paymentCard, validatedPromoCode } = params;

    callingRechargeStore.setSelectedPaymentCard(paymentCard);
    await callingRechargeStore.submitTransaction({
      amount,
      isAutoRechargeEnabled,
      paymentCardId: paymentCard.handleId,
      userCountryCode: userAccountStore.userCountryOfOrigin,
      validatedPromoCode,
    });

    if (callingRechargeStore.rechargeReceipt) {
      const txnPrams = qs.stringify({
        txnParams: JSON.stringify({
          oldBalance: callingRechargeStore.rechargeReceipt.oldBalance,
          newBalance: callingRechargeStore.rechargeReceipt.newBalance,
          promoAmount: callingRechargeStore.rechargeReceipt.promoAmount,
          cardHandleId: paymentCard.handleId,
        }),
      });
      history.push(
        `${BASE_PATHS.CALLING_RECHARGE_RECEIPT}/${callingRechargeStore.rechargeReceipt.id}?${txnPrams}`,
      );
    }
  };

  const handleManageArClick = () => {
    history.push(BASE_PATHS.CALLING_AUTO_RECHARGE);
  };

  const handleCallingAvailabilityModalClose = () => {
    callingRechargeStore.hideCallingAvailabilityModal();
  };

  const stepModules: StepModule[] = [];

  stepModules[Steps.Recharge] = {
    /* eslint-disable @typescript-eslint/indent */
    component: callingRechargeStore.paymentInfo &&
      !callingRechargeStore.isPaymentInfoLoading && (
        <Recharge
          paymentInfo={callingRechargeStore.paymentInfo}
          isAutoRechargeEnabled={
            callingRechargeStore.isAutoRechargeRecommended ||
            callingRechargeStore.isAutoRechargeSet
          }
          onFinish={handleRechargeStepFinish}
          onManageArClick={handleManageArClick}
          dataTestPrefix={DATA_TEST_ID_PREFIX}
          isCallingFeatureAvailable={callingRechargeStore.paymentInfo.isCallingAvailable}
          isLoading={callingRechargeStore.isRechargeLoading || userAccountStore.isLoading}
          userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
        />
      ),
  };

  return (
    <Spinner isSpinning={callingRechargeStore.isAnythingLoading}>
      <PageTitle
        title={t('recharge balance')}
        buttonsNode={
          <>
            <AdaptiveHelpButton />
            <AdaptiveCancelButton
              onClick={handleCancelButtonClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-cancel-btn`}
            />
          </>
        }
        hasBossLogoLink={isDesktop}
        hasFixedPosition
      />

      <div className="mt-10 mb-24">
        <Switch>
          {availableSteps.map((step) => {
            const module = stepModules[step];

            return (
              <Route
                key={stepPaths[step].toLowerCase()}
                path={`${basePath}${stepPaths[step]}`}
                render={() => module.component}
              />
            );
          })}
        </Switch>
      </div>
      <Modal
        isOpen={callingRechargeStore.isCallingAvailabilityModalVisible}
        onRequestClose={handleCallingAvailabilityModalClose}
        header={<Logo className="w-24" />}
      >
        {t(
          'We are sorry. At this time BOSS Revolution does not provide this service in your country.',
        )}
      </Modal>
    </Spinner>
  );
};

export default observer(Calling);
