import CallingPlan, { CallingPlanData } from '@root/models/CallingPlans/CallingPlan';

import {
  ActivePlan,
  AvailablePlan,
  NonPurchasablePlan,
  PromoOffer,
} from '@root/interfaces/contract/dtcImtuCallingPlans/Offers';

// is not a Combo Plan
export const isRegularPlan = (data: NonPurchasablePlan) => {
  return !data.promo_offers;
};

function getPromoOfferWithHighestPrice(promoOffers?: PromoOffer[]) {
  if (!promoOffers || promoOffers.length === 0) {
    return null;
  }

  let lowestPriceObject = promoOffers[0];
  let highestPrice = Number(lowestPriceObject.price);

  for (let i = 1; i < promoOffers.length; i += 1) {
    if (Number(promoOffers[i].price) > highestPrice) {
      highestPrice = Number(promoOffers[i].price);
      lowestPriceObject = promoOffers[i];
    }
  }

  return lowestPriceObject;
}

export const getCallingPlanDataFromResponse = (
  data: ActivePlan | AvailablePlan | NonPurchasablePlan,
  isNonPurchasable?: boolean,
): CallingPlanData => {
  const countryObjects = data.content.countries;
  const promoOffer = getPromoOfferWithHighestPrice(data.promo_offers);
  const price = Number(promoOffer?.price || data.price.display_amount);
  return {
    productId: data.product_code,
    planId: String(data.plan_id),
    classId: data.class_id,
    titleFull: data.content.title,
    titleShort: data.content.short_title || '',
    descriptionShort: data.content.short_description,
    descriptionFull: data.content.description,
    destinations: countryObjects.map((item) => {
      return {
        countryCode: item.country_code,
        description: item.description,
        descriptionIconUrl: item.description_icon_url,
        imageUrls: item.image_urls || [],
      };
    }),
    destinationFootnotes:
      data.content.footnotes?.map((footNoteObj) => {
        return {
          text: footNoteObj.note,
          iconUrl: footNoteObj.note_icon_url,
        };
      }) || [],
    maxFavoriteNumbers:
      'num_of_dest_allowed' in data ? data.num_of_dest_allowed : undefined,
    type: data.type,
    carrierLogos: countryObjects.map((item) => item.image_urls || []).flat(),
    minutesLeft: 'units' in data ? data.units : undefined,
    basePrice: price,
    currency: data.price.currency,
    expirationDate: 'expiry_timestamp' in data ? data.expiry_timestamp : undefined,
    comboPromoId: promoOffer?.promo_id,
    comboProducts: promoOffer?.products.map((product) => {
      return {
        productId: product.product_id,
        price: Number(product.price),
        discountPrice: Number(product.discounted_price),
        title: product.title,
        description: product.description,
        type: product.type,
      };
    }),
    comboPromoTagline: promoOffer?.tagline,
    txnId: 'transaction_id' in data ? data.transaction_id : undefined,
    isActive: 'status' in data ? Boolean(data.status) : false, // status is always 'A' if defined
    isSubscriptionType: data.is_auto_renewable,
    isSubscriptionActive:
      'is_auto_renew_active' in data ? data.is_auto_renew_active : false,
    isNoNPurchasable: isNonPurchasable,
    isExtendable: 'can_renew' in data && data.can_renew,
  };
};

export const convertToCollingPlan = (
  data: ActivePlan | AvailablePlan | NonPurchasablePlan,
): CallingPlan => {
  return new CallingPlan(getCallingPlanDataFromResponse(data));
};

export const convertToNonPurchasableCallingPlan = (
  data: ActivePlan | AvailablePlan | NonPurchasablePlan,
): CallingPlan => {
  return new CallingPlan(getCallingPlanDataFromResponse(data, true));
};
